import PropTypes from 'prop-types';
import { useRef, useState, useEffect, useMemo } from 'react';
import { useToggleState } from 'react-stately';
import { useFocusRing, useSwitch } from 'react-aria';
import { useTranslation } from 'react-i18next';

/** Hooks */
import { useUniqueId } from '../../../hooks/useUniqueId';
import { useOnResize } from '../../../hooks/useOnResize';

/** Components */
import Button from '../../Button';

/**
 * Switch field.
 *
 * @component
 * @param {object} props - The properties object.
 * @param {boolean} [props.isDisabled=false] - Whether the switch should be disabled.
 * @param {React.ReactNode|React.ReactNode[]} props.children - The label or content for the switch.
 * @param {boolean} [props.hasReduxValue] - Whether the Switch has a Redux value stored. Don't pass this prop it you're not using Redux.
 * @param {boolean} [props.isEnableable=false] - Whether the switch can be reenabled when disabled.
 * @param {'primary'|'secondary'} [props.color] - The color of the switch.
 * @param {Function} props.onChange - Callback function triggered when the switch state changes.
 * @returns {JSX.Element} The rendered Switch component.
 */
const Switch = ({ hasReduxValue, isEnableable = false, color, ...props }) => {
  const { t: __ } = useTranslation();
  const inputId = useUniqueId();

  const inputRef = useRef();
  const labelRef = useRef();

  const state = useToggleState(props);
  const { inputProps } = useSwitch(props, state, inputRef);
  const { isFocusVisible, focusProps } = useFocusRing();

  /** Class names */
  const classNames = useMemo(() => {
    const classes = ['switch'];
    state.isSelected && classes.push('is-selected');
    inputProps.disabled && classes.push('is-disabled');
    color && classes.push(`c-${color}`);
    hasReduxValue !== undefined && classes.push('has-redux');
    hasReduxValue && classes.push('has-redux-value');
    return classes.join(' ');
  }, [state.isSelected, inputProps.disabled, color, hasReduxValue]);

  /** Adjust the height of the enable button to match the label element's height. */
  const [labelHeight, setLabelHeight] = useState(0);
  const handleResize = () => {
    const labelHeight = labelRef.current.getBoundingClientRect().height;
    setLabelHeight(`${labelHeight}px`);
  };
  useOnResize(handleResize);
  useEffect(handleResize, []);

  return (
    <>
      {inputProps.disabled && isEnableable && (
        <Button
          className="switch-enable-button"
          aria-label={__('form.Enable this control')}
          aria-controls={inputId}
          style={{ '--label-height': labelHeight }}
          onPress={() => {
            labelRef.current.focus();
            props.onChange(true);
          }}
        />
      )}
      <label ref={labelRef} className={classNames}>
        <input ref={inputRef} id={inputId} className="visually-hidden" {...inputProps} {...focusProps} />
        <svg className="switch-control" width={50} height={30} aria-hidden="true" style={{ minWidth: '50px' }}>
          <rect x={4} y={4} width={42} height={22} rx={11} strokeWidth={1} />
          <circle cx={state.isSelected ? 35 : 15} cy={15} r={9} fill="white" />
          {isFocusVisible && <rect x={1} y={1} width={48} height={28} rx={14} fill="none" strokeWidth={2} />}
        </svg>
        {props.children}
      </label>
    </>
  );
};

Switch.propTypes = {
  isDisabled: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  hasReduxValue: PropTypes.bool,
  isEnableable: PropTypes.bool,
  color: PropTypes.oneOf(['primary', 'secondary']),
  onChange: PropTypes.func.isRequired,
};

export default Switch;
