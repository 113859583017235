/**
 * Return whether an object has a property.
 *
 * @param {object} object  The object
 * @param {string} property  The property name
 * @returns {boolean}  Whether the object has the property
 */
export const hasProperty = (object, property) => (object ? {}.hasOwnProperty.call(object, property) : false);

/**
 * Recursively flattens a nested object into a one-dimensional object.
 * The keys of the flattened object represent the path to each value in the original object.
 *
 * @param {object} obj - The object to flatten.
 * @param {string} [parentKey=''] - The base key for the current recursion, used to build the key paths.
 * @param {object} [result={}] - The accumulator object that holds the flattened result.
 * @returns {object} - A flattened object where nested keys are concatenated with dot notation.
 * @example
 * const nestedObject = {
 *   a: {
 *     b: {
 *       c: 1,
 *       d: 2,
 *     },
 *     e: 3,
 *   },
 *   f: 4,
 * };
 *
 * const flattened = flattenObject(nestedObject);
 * console.log(flattened);
 **  Output:
 **  {
 **    "a.b.c": 1,
 **    "a.b.d": 2,
 **    "a.e": 3,
 **    "f": 4,
 **  }
 */
export const flattenObject = (obj, parentKey = '', result = {}) => {
  for (const key in obj) {
    const value = obj[key];
    const newKey = parentKey ? `${parentKey}.${key}` : key;

    if (value !== null && typeof value === 'object' && !Array.isArray(value)) {
      /** Recursively handle nested objects */
      flattenObject(value, newKey, result);
    } else {
      /** Assign the value to the result with the full key path */
      result[newKey] = value;
    }
  }
  return result;
};

/**
 * Recursively simplifies an object by extracting the `value` property and removing `errorMessage` properties.
 * If an object contains a `value` property, its value is extracted and processed recursively.
 *
 * @param {object} obj - The object to simplify. It may contain nested objects with `value` and `errorMessage` properties.
 * @returns {object|any} - A simplified object with only the `value` properties retained, or the original value if not an object.
 * @example
 * const input = {
 *   type: { value: "offer", errorMessage: "" },
 *   qualityAndOrigin: {
 *     value: {
 *       biodiversityContracts: { value: true, errorMessage: "" }
 *     },
 *     errorMessage: ""
 *   }
 * };
 *
 * const simplified = simplifyObject(input);
 * console.log(simplified);
 ** Output:
 ** {
 **   type: "offer",
 **   qualityAndOrigin: {
 **     biodiversityContracts: true
 **   }
 ** }
 */
export const simplifyObject = (obj) => {
  if (typeof obj === 'object' && obj !== null && !Array.isArray(obj)) {
    if ('value' in obj) {
      return simplifyObject(obj.value);
    }
    return Object.keys(obj).reduce((acc, key) => {
      acc[key] = simplifyObject(obj[key]);
      return acc;
    }, {});
  }
  return obj;
};

/**
 * Transforms an object into a structure suitable for Redux forms state,
 * where each field is mapped to an object containing its value and an empty error message.
 * The transformation is applied recursively to handle nested objects.
 * If a key is `mowingDate` or `photo`, the values of the object's children are returned without transformation.
 *
 * @param {object} data - The input object to transform.
 * @returns {object} The new object.
 * @example
 * const input = {
 *   type: "offer",
 *   qualityAndOrigin: {
 *     biodiversityContracts: true
 *   }
 * };
 *
 * const result = transformToReduxData(input);
 * console.log(result);
 ** Output:
 ** {
 **   type: { value: "offer", errorMessage: "" },
 **   qualityAndOrigin: {
 **     value: {
 **       biodiversityContracts: { value: true, errorMessage: "" }
 **     },
 **     errorMessage: ""
 **   }
 ** };
 */
export const transformToReduxFormData = (data) => {
  return Object.fromEntries(
    Object.entries(data).map(([field, value]) => {
      if (value && !['mowingDate', 'photo'].includes(field) && typeof value === 'object' && !Array.isArray(value)) {
        return [field, { value: transformToReduxFormData(value), errorMessage: '' }];
      } else {
        return [field, { value, errorMessage: '' }];
      }
    })
  );
};
