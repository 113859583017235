import PropTypes from 'prop-types';

/*******
 * APP *
 *******/

/**
 * The colors of the app
 */
const colors = ['primary', 'secondary', 'sage', 'lichen', 'cream'];
export const colorPropTypes = PropTypes.oneOf(['white', ...colors]);
export const colorPropTypesNoWhite = PropTypes.oneOf(colors);
export const buttonColorPropTypes = PropTypes.oneOf(['white', 'thyme', 'rosewood', ...colors]);

/**
 * The image formats of the app -- Must match the custom image sizes settings in /app/setup.php
 */
export const imagePropTypes = {
  /** Image alt */
  alt: PropTypes.string,
  /** Landscape */
  landscape: PropTypes.shape({
    w3840h2560: PropTypes.string,
    w2880h1920: PropTypes.string,
    w1920h1280: PropTypes.string,
    w1440h960: PropTypes.string,
    w960h640: PropTypes.string,
  }),
  /** Portrait */
  portrait: PropTypes.shape({
    w2560h3840: PropTypes.string,
    w1920h2880: PropTypes.string,
    w1280h1920: PropTypes.string,
    w960h1440: PropTypes.string,
  }),
  /** Square */
  square: PropTypes.shape({
    w2560h2560: PropTypes.string,
    w1920h1920: PropTypes.string,
    w1440h1440: PropTypes.string,
    w960h960: PropTypes.string,
  }),
  /** Resized */
  resized: PropTypes.shape({
    w3840: PropTypes.string,
    w2880: PropTypes.string,
    w1920: PropTypes.string,
    w1440: PropTypes.string,
    w960: PropTypes.string,
  }),
  /** Full */
  full: PropTypes.string,
  /** Thumbnail */
  thumbnail: PropTypes.string,
};

/**
 * The base props of a link
 */
export const linkPropTypes = {
  url: PropTypes.string.isRequired,
  target: PropTypes.string,
  title: PropTypes.string,
};

/**
 * Props of a location lat-lng
 */
export const latLngPropTypes = {
  /** The latitude of a location */
  lat: PropTypes.number,
  /** The longitude of a location */
  lng: PropTypes.number,
};

/**
 * Prop types common to all fields.
 *
 * @typedef {object} FieldPropTypes
 * @property {string} label - The label of the field.
 * @property {string} [description] - The description of the field.
 * @property {string} [errorMessage] - The error message of the field.
 * @property {boolean} [isDisabled] - Indicates if the input is disabled.
 * @property {boolean} [isReadOnly] - Indicates if the input is read-only.
 * @property {boolean} [isRequired] - Indicates if the input is required.
 * @property {('valid'|'invalid')} [validationState] - The validation state of the input.
 * @property {boolean} [autoFocus] - Indicates if the input should autofocus on render.
 */
export const fieldPropTypes = {
  label: PropTypes.string.isRequired,
  description: PropTypes.string,
  errorMessage: PropTypes.string,
  isDisabled: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  isRequired: PropTypes.bool,
  validationState: PropTypes.oneOf(['valid', 'invalid']),
  autoFocus: PropTypes.bool,
};

/********
 * Rest *
 ********/

/**
 * Returned by all routes listing pages
 */
export const pagePropTypes = {
  /** The id of the page */
  id: PropTypes.number,
  /** The url of the page */
  url: PropTypes.string.isRequired,
  /** The rest url of the page */
  restUrl: PropTypes.string.isRequired,
  /** The title of the page */
  title: PropTypes.string.isRequired,
  /** The name of the template for the page */
  templateName: PropTypes.string.isRequired,
  /** A slug for the page, regardless the language */
  pageName: PropTypes.string.isRequired,
  /** The id of the parent of the page */
  parentId: PropTypes.number,
  /** The 2-letter language code of the page -- false is provided while the pages are loading */
  lang: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
  /** An object of translations for the page {'language_code': 'url'} -- array is provided while the pages are loading */
  translations: PropTypes.oneOfType([PropTypes.objectOf(PropTypes.string), PropTypes.array]).isRequired,
  /** The type of the page -- Added by <AppRouter /> to the <Template /> `page` prop  -- '' is provided while the pages are loading */
  type: PropTypes.oneOf(['page', 'user', '404', '']),
  /** Additional data -- The type is array when there is no data */
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

/**
 * Returned by each single page route
 */
export const contentPropTypes = {
  /** The meta data of the page */
  meta: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
  }).isRequired,
};

/**
 * An user
 */
export const userPropTypes = PropTypes.shape({
  lastName: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  userDetails: PropTypes.shape({
    companyName: PropTypes.string,
    streetName: PropTypes.string.isRequired,
    houseNumber: PropTypes.string,
    zipCode: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    tel: PropTypes.string.isRequired,
    tel2: PropTypes.string,
    contactMethod: PropTypes.oneOf(['contactDetailsHidden', 'contactDetailsVisible']).isRequired,
    visibleData: PropTypes.arrayOf(
      PropTypes.oneOf(['nameIsVisible', 'addressIsVisible', 'emailIsVisible', 'phoneIsVisible'])
    ),
  }).isRequired,
  gpsCoords: PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
  }),
}).isRequired;

/**
 * An opportunity
 */
export const opportunityPropTypes = PropTypes.shape({
  id: PropTypes.number.isRequired,
  status: PropTypes.string.isRequired, // e.g., "published"
  type: PropTypes.oneOf(['offer', 'request']).isRequired,
  contact: PropTypes.shape({
    lastName: PropTypes.string,
    firstName: PropTypes.string,
    companyName: PropTypes.string,
    streetName: PropTypes.string,
    houseNumber: PropTypes.string,
    zipCode: PropTypes.string,
    city: PropTypes.string.isRequired,
    email: PropTypes.string,
    tel: PropTypes.string,
    tel2: PropTypes.string,
    gpsCoords: PropTypes.shape({
      lat: PropTypes.number.isRequired,
      lng: PropTypes.number.isRequired,
    }),
  }).isRequired,
  qualityAndOrigin: PropTypes.shape({
    biodiversityContracts: PropTypes.bool.isRequired,
    bioCertifiedFarm: PropTypes.bool.isRequired,
    pesticideFree: PropTypes.bool.isRequired,
    reducedFertilization: PropTypes.bool.isRequired,
  }).isRequired,
  packagingAndSize: PropTypes.shape({
    quantity: PropTypes.number.isRequired,
    format: PropTypes.oneOf(['squareBales', 'roundBales', 'bulk']).isRequired,
    diameter: PropTypes.number, // Only for "roundBales"
    bulkType: PropTypes.oneOf(['', 'bag', 'bigBag']), // Only for "bulk"
  }).isRequired,
  mowingDate: PropTypes.shape({
    startDate: PropTypes.string.isRequired, // ISO format (YYYY-MM-DD)
    endDate: PropTypes.string.isRequired, // ISO format (YYYY-MM-DD)
  }).isRequired,
  price: PropTypes.number.isRequired,
  additionalOptions: PropTypes.shape({
    storageType: PropTypes.oneOf(['dry', 'ventilated', 'outside']).isRequired,
    delivery: PropTypes.bool.isRequired,
    minQuantity: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]).isRequired,
  }).isRequired,
});

/*******
 * ACF *
 *******/

/**
 * The content of the ACF "Page header" group
 */
export const headerPropTypes = PropTypes.shape({
  title: PropTypes.string,
  headline: PropTypes.string,
  link: PropTypes.oneOfType([PropTypes.string, PropTypes.shape(linkPropTypes)]),
  color: colorPropTypesNoWhite,
});

/**
 * The content of the ACF "Flexible content" group
 */
export const flexiblePropTypes = PropTypes.arrayOf(PropTypes.shape()); // TODO
