import PropTypes from 'prop-types';
import { useRef, useMemo } from 'react';
import { useSliderState } from 'react-stately';

import { mergeProps, useFocusRing, useNumberFormatter, useSlider, useSliderThumb } from 'react-aria';

/**
 * Slider field.
 *
 * @component
 * @param {object} props - The properties object.
 * @param {string} [props.label] - The label for the slider.
 * @param {object} [props.formatOptions] - Number formatting options for the slider values.
 * @param {string} [props.name] - The name attribute for the slider.
 * @param {number} [props.minValue] - The minimum value of the slider.
 * @param {number} [props.maxValue] - The maximum value of the slider.
 * @param {number} [props.step] - The step value for the slider.
 * @param {number|number[]} [props.value] - The current value of the slider.
 * @param {number|number[]} [props.defaultValue] - The default value of the slider.
 * @param {Function} [props.onChange] - Callback when the value changes.
 * @param {boolean} [props.isRequired] - Whether the slider is required.
 * @param {boolean} [props.isDisabled] - Whether the slider is disabled.
 * @param {"horizontal"|"vertical"} [props.orientation] - The orientation of the slider.
 * @param {boolean} [props.hasReduxValue] - Whether the Slider has a Redux value stored. Don't pass this prop it you're not using Redux.
 * @param {'primary'|'secondary'} [props.color] - The color of the slider.
 * @param {string} [props.outputPrefix=''] - A prefix for the output value.
 * @param {string} [props.outputSuffix=''] - A suffix for the output value.
 * @param {string} [props.className] - The class names to add to the element.
 * @returns {JSX.Element} The rendered slider component.
 */
const Slider = ({ hasReduxValue, color, outputPrefix = '', outputSuffix = '', className = '', ...props }) => {
  const { label, name, isRequired } = props;

  const trackRef = useRef();
  const numberFormatter = useNumberFormatter(props.formatOptions);
  const state = useSliderState({ ...props, numberFormatter });
  const { groupProps, trackProps, labelProps, outputProps } = useSlider(props, state, trackRef);

  /** Class names */
  const classNames = useMemo(() => {
    const classes = [`slider l-${state.orientation}`];
    isRequired && classes.push('is-required');
    state.isDisabled && classes.push('is-disabled');
    className && classes.push(className);
    color && classes.push(`c-${color}`);
    hasReduxValue !== undefined && classes.push('has-redux');
    hasReduxValue && classes.push('has-redux-value');
    return classes.join(' ');
  }, [state.orientation, isRequired, state.isDisabled, className, color, hasReduxValue]);

  return (
    <span {...groupProps} className={classNames}>
      {/** Create a container for the label and output element. */}
      {label && (
        <span className="slider-label">
          <label {...labelProps}>{label}</label>
          <output {...outputProps}>{outputPrefix + state.getThumbValueLabel(0) + outputSuffix}</output>
        </span>
      )}
      {/** The track element holds the visible track line and the thumb. */}
      <span {...trackProps} ref={trackRef} className={`track ${state.isDisabled ? 'disabled' : ''}`}>
        <Thumb index={0} state={state} trackRef={trackRef} name={name} />
      </span>
    </span>
  );
};

Slider.propTypes = {
  label: PropTypes.string,
  formatOptions: PropTypes.object,
  name: PropTypes.string,
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  step: PropTypes.number,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
  defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
  onChange: PropTypes.func,
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
  orientation: PropTypes.oneOf(['horizontal', 'vertical']),
  hasReduxValue: PropTypes.bool,
  color: PropTypes.oneOf(['primary', 'secondary']),
  outputPrefix: PropTypes.string,
  outputSuffix: PropTypes.string,
  className: PropTypes.string,
};

/**
 * Thumb component representing the draggable handle of the slider.
 *
 * @component
 * @param {object} props - The properties object.
 * @param {object} props.state - The slider state.
 * @param {object} props.trackRef - A reference to the track element.
 * @param {number} props.index - The index of the thumb.
 * @param {string} [props.name] - The name attribute for the thumb input.
 * @returns {JSX.Element} The rendered thumb component.
 */
const Thumb = (props) => {
  const { state, trackRef, index, name } = props;
  const inputRef = useRef();
  const { thumbProps, inputProps, isDragging } = useSliderThumb({ index, trackRef, inputRef, name }, state);

  const { focusProps, isFocusVisible } = useFocusRing();
  return (
    <span {...thumbProps} className={`thumb ${isFocusVisible ? 'has-focus' : ''} ${isDragging ? 'is-dragging' : ''}`}>
      <span className="visually-hidden">
        <input ref={inputRef} {...mergeProps(inputProps, focusProps)} />
      </span>
    </span>
  );
};

Thumb.propTypes = {
  state: PropTypes.object.isRequired,
  trackRef: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  name: PropTypes.string,
};

export default Slider;
