import PropTypes from 'prop-types';
import { useRef, useState, useMemo, useCallback, useImperativeHandle, memo, forwardRef, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { kebabCase } from 'lodash';
import { languages as languageNames } from '../../i18n';

/** PropTypes */
import { colorPropTypesNoWhite } from '../../propTypes';

/** Redux */
import { isLogged } from '../../redux/userSlice';

/** Hooks */
import { useRecaptcha } from '../../hooks/useRecaptcha';
import { useCsrfToken } from '../../hooks/useCsrfToken';
import { useFormValidation } from '../../hooks/useFormValidation';

/** Utils */
import { FormSender } from '../../util/Fetch';
import { isHTML } from '../../util/String';

/** Components */
import { CustomField } from './fields/CustomField';
import Button from '../Button';
import Link from '../Link';
import CookieNotice from '../CookieNotice';

/**
 * @typedef {object} Field - A field.
 * @property {string} type - The type of field ('text', 'email', 'textarea', etc.).
 * @property {string} label - The label text for the field.
 * @property {boolean} [isRequired] - Whether the field is required.
 * @property {number} [minLength] - Minimum length requirement.
 * @property {number} [maxLength] - Maximum length requirement.
 * @property {any} [default] - Default value for the field.
 * @property {object} [fields] - Nested fields for 'group' type.
 * @property {Array} [radios] - Radio options for 'radioGroup' type.
 * @property {boolean} [skipValidation] - Whether to skip type-specific validation.
 */

/**
 * Form Component
 *
 * A reusable form component that supports various field types, validation, and integration with Google reCAPTCHA.
 * It handles CSRF tokens, HTTP Referer, and user authentication headers for secure form submission.
 *
 * @component
 * @param {object} props - The properties object.
 * @param {'contact'|'login'|'verify-email'|'register'|'create-opportunity'} props.type - The type of the form.
 * @param {'contact'|'login'|'user/verify-email'|'user/register'|'user/create-opportunity'|'user/contact'} props.action - The app route to post the form to.
 * @param {boolean} [props.autoComplete=false] - Whether to use autocomplete for the form.
 * @param {boolean} [props.isDisabled=false] - Whether the form should be disabled.
 * @param {Field[]} [props.fields] - An array of field definitions for the form.
 * @param {JSX.Element|JSX.Element[]} [props.children] - Custom JSX children for the form.
 * @param {string} props.label - A label for the form, used for accessibility purposes.
 * @param {string} [props.buttonLabel] - The label for the submit button.
 * @param {string} [props.buttonColor='primary'] - The theme color for the submit button.
 * @param {boolean} [props.hasResetButton=false] - Whether the form should display a reset button.
 * @param {string} [props.resetButtonLabel] - The label for the reset button.
 * @param {Function} [props.onReset] - Callback for the form's onReset event.
 * @param {Function} [props.onSubmit] - Callback for the form's onSubmit event.
 * @param {Function} [props.onError] - Callback when the form validation fails.
 * @param {Function} [props.onSend] - Callback when the form begins submission.
 * @param {Function} [props.onResponse] - Callback when the form receives a response.
 * @param {Function} [props.onSent] - Callback when the form data is successfully sent.
 * @param {Function} [props.onLogin] - Callback when the user logs in.
 * @param {Function} [props.onLogout] - Callback when the user logs out.
 * @param {Function} [props.onVerify] - Callback when the users verifies its e-mail.
 * @param {React.Ref} forwardedRef - A ref for accessing the form's imperative methods.
 * @returns {JSX.Element} The rendered Form component.
 *
 * You have to provide either:
 * - the fields prop
 * - the children prop
 */
const Form = forwardRef(
  ({ type, action, autoComplete = false, isDisabled = false, fields, children, ...props }, forwardedRef) => {
    const { label, buttonLabel, buttonColor = 'primary', hasResetButton = false, resetButtonLabel } = props;
    const { t: __, i18n } = useTranslation();

    /** CSRF Token, HTTP Referer & reCAPTCHA */
    const csrfToken = useCsrfToken(`post-form-${type}`);
    const httpReferer = useSelector((state) => state.app.currentPage.url);
    const recaptchaKey = useSelector((state) => state.options.settings.recaptcha);
    const loadRecaptcha = useRecaptcha(recaptchaKey);

    /** User */
    const isUserLogged = useSelector(isLogged);
    const user = useSelector((state) => state.user);
    const userLoggedMessage = `${__('form.Logged in as')} ${user.name}.`;
    const lostPasswordUrl = useSelector((state) => state.options.lostPasswordUrl);

    /** Load Google reCAPTCHA if the user is logged */
    isUserLogged && loadRecaptcha();

    /** Form */
    const fallbackRef = useRef();
    const formRef = forwardedRef || fallbackRef;
    const formProps = useMemo(
      () => ({
        action: `${process.env.REACT_APP_REST_URL}${action}`,
        method: 'POST',
        autoComplete: autoComplete ? 'on' : 'off',
        noValidate: true,
        onFocus: loadRecaptcha,
      }),
      [action, autoComplete, loadRecaptcha]
    );
    const customFormData = useMemo(() => new FormData(), []);

    /** Validation */
    const validateForm = useFormValidation(fields, action);
    const defaultMessages = useMemo(() => ({ errors: '', status: '', form: '', sent: '' }), []);
    const [messages, setMessages] = useState(defaultMessages);
    const [isSending, setIsSending] = useState(false);
    const [isSent, setIsSent] = useState(false);

    /**
     * Display an error message from the api or a default message.
     *
     * @param {object} response - The response trom the api.
     * @param {string} defaultMessage The default message.
     */
    const setSenderError = useCallback(
      (response, defaultMessage) => {
        setMessages({
          ...defaultMessages,
          form: response.data?.message ?? response.error ?? defaultMessage,
        });
      },
      [defaultMessages]
    );

    /**
     * Process the form sender response.
     *
     * @param {JSON} response - The form sender response.
     */
    const onSenderResponse = useCallback(
      (response) => {
        const { sent, data } = response;
        setIsSending(false);
        props.onResponse && props.onResponse(sent && (data.isSent || data.isSaved));
        if (!sent) {
          setSenderError(response, __('form.Error sending your message'));
          return;
        }

        if (data.isSent) {
          /** A message is sent. */
          setIsSent(true);
          setMessages({ ...defaultMessages, sent: __('form.Message sent') });
          props.onSent && props.onSent(data);
        } else if (data.isSent === false) {
          /** Something failed during sending. */
          setIsSent(false);
          setSenderError(response, data.message);
        } else if (data.isSaved) {
          /** Something is saved. */
          setIsSent(true);
          setMessages({ ...defaultMessages, sent: data.message });
          props.onSent && props.onSent(data);
        } else if (data.isSaved === false) {
          /** Something failed during save. */
          setSenderError(response, __('form.Error while saving'));
        } else if (data.isLoggedIn && data.name) {
          /** The user has successfully logged in. */
          setMessages(defaultMessages);
          props.onLogin && props.onLogin(data);
        } else if (data.isLoggedOut) {
          /** The user has logged out. */
          setMessages({ ...defaultMessages, status: __('form.You have been disconnected') });
          props.onLogout && props.onLogout(data);
        } else if (data.isLoggedIn === false) {
          /** User authentication failed. */
          setSenderError(response, __('form.Error during authentication'));
        } else if (data.isVerified) {
          /** The user e-mail was verified. */
          setMessages({ ...defaultMessages, sent: data.message });
          props.onVerify && props.onVerify(data);
        } else if (data.isVerified === false) {
          /** Verification of the user e-mail failed. */
          setSenderError(response, __('form.Error verifying your e-mail'));
        }
      },
      [__, defaultMessages, props, setSenderError]
    );

    /**
     * Send the form.
     *
     * @param {string} captchaToken - The reCAPTCHA token.
     */
    const sendForm = useCallback(
      (captchaToken) => {
        /** Set the form data */
        const formData = customFormData.has('data') ? customFormData : new FormData(formRef.current);
        formData.append('type', type);
        formData.append('language', languageNames[i18n.language]);
        formData.append('action', action === 'login' && isUserLogged ? 'logout' : action);

        /** Create the user header string and remove user credentials from the form data */
        let userHeader = '';
        if (isUserLogged) {
          userHeader = `${user.email}:${user.token}`;
        } else if (action === 'login') {
          userHeader = `${formData.get('login')}:${formData.get('password')}`;
          formData.delete('login');
          formData.delete('password');
        } else if (action === 'user/register') {
          userHeader = formData.get('credentials');
          formData.delete('credentials');
        }

        /** Send the form */
        const sender = new FormSender(formData, formProps.action);
        sender.addHeader('X-CSRF-TOKEN', csrfToken);
        sender.addHeader('X-HTTP-REFERER', httpReferer);
        sender.addHeader('X-APP-LANGUAGE', i18n.language);
        captchaToken && sender.addHeader('X-CAPTCHA-TOKEN', captchaToken);
        (action === 'login' || (action.startsWith('user/') && action !== 'user/verify-email')) &&
          sender.addHeader('X-APP-USER', encodeURIComponent(window.btoa(userHeader)));
        sender.addListener('response', (data) => onSenderResponse(data));
        sender.send();
        setIsSending(true);
        props.onSend && props.onSend();

        /** Set the status message */
        setMessages({
          ...defaultMessages,
          status:
            action === 'login'
              ? !isUserLogged
                ? __('form.Authentication in progress')
                : __('form.Logout in progress')
              : __('form.Sending in progress'),
        });
      },
      [__, action, csrfToken, customFormData, defaultMessages, formProps.action, formRef,
      httpReferer, i18n.language, isUserLogged, onSenderResponse, props, type, user] // prettier-ignore
    );

    /**
     * Get a token from Google reCAPTCHA and then send the form.
     */
    const getCaptchaTokenAndSendForm = useCallback(() => {
      if (window.grecaptcha) {
        window.grecaptcha.ready(() =>
          window.grecaptcha.execute(recaptchaKey, { action: action.replaceAll('-', '_') }).then(sendForm)
        );
      } else {
        setSenderError({}, __('form.Error loading Google reCAPTCHA'));
      }
    }, [__, action, recaptchaKey, sendForm, setSenderError]);

    /**
     * Submit the form.
     *
     * Validate the form, Check Google reCAPTCHA and then send the form.
     *
     * @param {SubmitEvent} event - The form submit event.
     */
    const submitForm = useCallback(
      (event) => {
        event.preventDefault();
        const [isValid, errors] = validateForm(formRef.current);
        setMessages({ ...defaultMessages, errors, form: isValid ? '' : __('form.The form contains errors') });
        if (isValid) {
          getCaptchaTokenAndSendForm();
        } else {
          props.onError && props.onError(errors);
        }
      },
      [__, formRef, validateForm, defaultMessages, getCaptchaTokenAndSendForm, props]
    );

    /** Expose methods and the form status to the referrer */
    useImperativeHandle(
      forwardedRef,
      () => ({
        setMessages,
        defaultMessages,
        setFormData: (name, value) => customFormData.set(name, value),
        setFormFile: (name, value, fileName) => customFormData.set(name, value, fileName),
        getCaptchaTokenAndSendForm,
        sendForm,
        getIsSending: () => isSending,
        getIsSent: () => isSent,
        reset: () => {
          setIsSent(false);
          setMessages(defaultMessages);
          for (const key of customFormData.keys()) {
            customFormData.delete(key);
          }
        },
      }),
      [defaultMessages, getCaptchaTokenAndSendForm, sendForm, customFormData, isSending, isSent]
    );

    /**
     * Gets the error message for a field.
     *
     * @param {string} name - The name of the field.
     * @returns {string} The error message for the field, if any.
     */
    const getErrorMessage = useCallback(
      (name) => {
        if (!messages.errors) return '';
        return typeof messages.errors === 'object' ? messages.errors[name] || '' : '';
      },
      [messages.errors]
    );

    /**
     * Gets the initial value of a field.
     *
     * @param {Field} field - The field.
     * @returns {string} The value of the field, if any.
     */
    const getValue = useCallback((field) => field?.value || undefined, []);

    return (
      <form
        ref={formRef}
        {...formProps}
        className={`form ${type}-form ${isUserLogged ? 'is-logged' : ''} ${isDisabled ? 'is-disabled' : ''}`}
        onSubmit={(event) => (props.onSubmit ? props.onSubmit(event) : submitForm(event))}
      >
        <h3 className="visually-hidden">{label}</h3>
        {fields
          ? Object.entries(fields).map(([name, { label, ...field }], index) => (
              <Fragment key={index}>
                {field.type === 'group' ? (
                  <fieldset className={`fieldset ${field.isRequired ? 'is-required' : ''} fieldset-${kebabCase(name)}`}>
                    <legend className="fieldset-title" id={`fieldset-${kebabCase(name)}-title`}>
                      {label}
                    </legend>
                    <CustomField
                      {...field}
                      name={name}
                      label={label}
                      ariaLabelledby={`fieldset-${kebabCase(name)}-title`}
                      isDisabled={isDisabled || field.isDisabled || isSent || isSending}
                      getValue={() => getValue(field)}
                      getErrorMessage={getErrorMessage}
                    />
                  </fieldset>
                ) : (
                  <CustomField
                    {...field}
                    name={name}
                    label={label}
                    isDisabled={isDisabled || field.isDisabled || isSent || isSending}
                    getValue={() => getValue(field)}
                    getErrorMessage={getErrorMessage}
                  />
                )}
              </Fragment>
            ))
          : children && <>{children}</>}

        {(isSent || (isUserLogged && action === 'login')) && (
          <p className="form-sent">{isSent ? messages.sent : userLoggedMessage}</p>
        )}
        {messages.status && <p className="form-status">{messages.status}</p>}
        {messages.form &&
          (isHTML(messages.form) ? (
            <div className="form-error" dangerouslySetInnerHTML={{ __html: messages.form }} />
          ) : (
            <p className="form-error">{messages.form}</p>
          ))}

        <div className={`form-submit ${isSent ? 'is-sent' : ''}`}>
          {action !== 'login' && !isUserLogged ? (
            <div>
              <FormCookieNotice />
            </div>
          ) : (
            !isUserLogged && (
              <a className="link lost-password" href={lostPasswordUrl}>
                {__('button.Lost password')}
              </a>
            )
          )}
          <div>
            {hasResetButton && (
              <Button
                layout="plain"
                color="rosewood"
                type="reset"
                className="reset-button"
                isDisabled={isDisabled || isSent || isSending}
                onPress={() => (props.onReset ? props.onReset() : formRef.current.reset())}
              >
                {resetButtonLabel ? resetButtonLabel : __('button.Reset')}
              </Button>
            )}
            <Button
              layout="plain"
              color={buttonColor}
              type="submit"
              className="submit-button"
              isDisabled={isDisabled || isSent || isSending}
            >
              {buttonLabel ? buttonLabel : __('button.Send')}
            </Button>
          </div>
        </div>
      </form>
    );
  }
);
Form.displayName = 'Form';

Form.propTypes = {
  type: PropTypes.oneOf(['contact', 'login', 'verify-email', 'register', 'create-opportunity']).isRequired,
  action: PropTypes.oneOf([
    'contact',
    'login',
    'user/verify-email',
    'user/register',
    'user/create-opportunity',
    'user/contact',
  ]).isRequired,
  autoComplete: PropTypes.bool,
  isDisabled: PropTypes.bool,
  fields: PropTypes.objectOf(
    PropTypes.shape({
      type: PropTypes.oneOf([
        'text',
        'email',
        'url',
        'tel',
        'password',
        'textarea',
        'group',
        'radioGroup',
        'checkbox',
        'checkboxGroup',
        'switch',
        'hidden',
        'calendar',
      ]).isRequired,
      label: PropTypes.string.isRequired,
      isRequired: PropTypes.bool,
      minLength: PropTypes.number,
      maxLength: PropTypes.number,
      default: PropTypes.any,
      fields: PropTypes.object, // For group type
      radios: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string.isRequired,
          label: PropTypes.string.isRequired,
          fields: PropTypes.object,
        })
      ),
      skipValidation: PropTypes.bool,
      isDisabled: PropTypes.bool,
      placeholder: PropTypes.string,
    })
  ),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  label: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string,
  buttonColor: colorPropTypesNoWhite,
  hasResetButton: PropTypes.bool,
  resetButtonLabel: PropTypes.string,
  onReset: PropTypes.func,
  onSubmit: PropTypes.func,
  onError: PropTypes.func,
  onSend: PropTypes.func,
  onResponse: PropTypes.func,
  onSent: PropTypes.func,
  onLogin: PropTypes.func,
  onLogout: PropTypes.func,
  onVerify: PropTypes.func,
};

/**
 * FormCookieNotice Component
 *
 * @component
 * @param {object} props - The properties object.
 * @returns {JSX.Element} The rendered component.
 */
const FormCookieNotice = (props) => {
  const { t: __ } = useTranslation();

  return (
    <CookieNotice>
      <h6>{__('cookie.Cookie information')}</h6>
      <p>
        {__('cookie.Forms are protected...')}
        <Link url="https://www.google.com/policies/privacy/" target="_blank" title={__('cookie.Privacy Policy')} />
        {__('cookie.and')}
        <Link url="https://www.google.com/policies/terms/" target="_blank" title={__('cookie.Terms of Service')} />
        {__('cookie.apply')}
      </p>
    </CookieNotice>
  );
};

export default memo(Form);
