import { configureStore } from '@reduxjs/toolkit';

import appReducer from './appSlice';
import pagesReducer from './pagesSlice';
import opportunitiesReducer from './opportunitiesSlice';
import citiesReducer from './citiesSlice';
import optionsReducer from './optionsSlice';
import userReducer from './userSlice';

export default configureStore({
  reducer: {
    app: appReducer,
    pages: pagesReducer,
    opportunities: opportunitiesReducer,
    cities: citiesReducer,
    options: optionsReducer,
    user: userReducer,
  },
});
