import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

/** Redux */
import { isLogged } from '../redux/userSlice';

/** Components */
import LoginMenu from './menus/LoginMenu';
import RegisterMenu from './menus/RegisterMenu';

/**
 * A component that renders menus for user authentication actions: login and registration.
 *
 * @component
 * @param {object} props - The properties object.
 * @param {string} [props.loginButtonColor] - The color for the login button.
 * @param {string} [props.registerButtonColor] - The color for the register button.
 * @param {boolean} [props.redirect=true] - Whether to redirect the user on login.
 * @param {boolean} [props.shouldOpenLoginOnMount=false] - Whether the login menu should be opened on mount.
 * @param {string} [props.layout] - The layout to apply to the element.
 * @returns {JSX.Element} The rendered component.
 */
const LoginRegister = ({
  loginButtonColor = 'cream',
  registerButtonColor = 'sage',
  redirect = true,
  shouldOpenLoginOnMount = false,
  layout,
  ...props
}) => {
  const isUserLogged = useSelector(isLogged);

  /** Class names */
  const classNames = useMemo(() => {
    const classes = ['login-register'];
    layout && classes.push(`l-${layout}`);
    return classes.join(' ');
  }, [layout]);

  return (
    <div className={classNames}>
      <LoginMenu buttonColor={loginButtonColor} redirect={redirect} shouldOpenOnMount={shouldOpenLoginOnMount} />
      {!isUserLogged && <RegisterMenu buttonColor={registerButtonColor} />}
    </div>
  );
};

LoginRegister.propTypes = {
  loginButtonColor: PropTypes.string,
  registerButtonColor: PropTypes.string,
  redirect: PropTypes.bool,
  shouldOpenLoginOnMount: PropTypes.bool,
  layout: PropTypes.oneOf(['fixed']),
};

export default LoginRegister;
