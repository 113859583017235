import { PropTypes } from 'prop-types';
import { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

/** Redux */
import { isLogged, saveUser, deleteUser, savePagesList as saveUserPages } from '../../redux/userSlice';

/** Utils */
import { saveCookie, deleteCookie } from '../../util/Cookies';

/** Components */
import Form from './Form';

/**
 * LoginForm Component.
 *
 * A form component for user login and logout functionality.
 * Displays login fields if the user is not logged in and a logout button otherwise.
 * Integrates with Redux for state management and handles cookies for session management.
 *
 * @component
 * @param {object} props - The properties object.
 * @param {Function} [props.onLogin] - Callback function executed after a successful login.
 *                                     Receives an object containing user information and pages.
 * @param {Function} [props.onLogout] - Callback function executed after logout.
 * @returns {JSX.Element} The rendered LoginForm component.
 */
const LoginForm = ({ onLogin = () => {}, onLogout = () => {}, ...props }) => {
  const dispatch = useDispatch();
  const { t: __ } = useTranslation();

  const isUserLogged = useSelector(isLogged);

  const fields = useMemo(
    () =>
      (!isUserLogged
        ? {
            login: { type: 'text', label: __('form.E-mail'), isRequired: true },
            password: { type: 'password', label: __('form.Password'), isRequired: true, skipValidation: true },
          }
        : {}), // prettier-ignore
    [__, isUserLogged]
  );

  return (
    <Form
      type="login"
      action="login"
      fields={fields}
      label={!isUserLogged ? __('form.Login form') : __('form.Logout form')}
      buttonLabel={!isUserLogged ? __('button.Login') : __('button.Logout')}
      onLogin={(data) => {
        const { message, pages, ...user } = data;
        saveCookie(process.env.REACT_APP_USER_COOKIE, JSON.stringify(user), 30);
        dispatch(saveUserPages(pages));
        dispatch(saveUser(user));
        onLogin({ user, pages });
      }}
      onLogout={(data) => {
        deleteCookie(process.env.REACT_APP_USER_COOKIE);
        dispatch(deleteUser());
        onLogout();
      }}
    />
  );
};

LoginForm.propTypes = {
  onLogin: PropTypes.func,
  onLogout: PropTypes.func,
};

export default LoginForm;
