/**
 * Add a zero before a number if it is lower than 10
 *
 * @param {number} number  The number
 * @returns {string}  The 2-character number
 */
const addZero = (number) => `${number < 10 ? '0' : ''}${number.toString()}`;

/**
 * Format a date
 *
 * @param {string} date (YYYY-mm-dd)|(YYYY-mm-ddTHH:i:ss)||(YYYY-mm-dd HH:i:ss)  The date to format
 * @returns {string} (dd.mm.YY)  The formatted date
 */
export const formatDate = (date) => {
  const delimiter = date.indexOf('T') > 0 ? 'T' : ' ';
  const dateArray = date.split(delimiter)[0].split('-');
  dateArray[0] = dateArray[0].substring(2);
  return dateArray.join('-');
};

/**
 * Convert a string date to a Date object
 *
 * @param {string} date (YYYY-mm-dd)  The date to convert
 * @returns {Date}  The converted date
 */
export const stringToDate = (date) => {
  const [y, m, d] = date.split('-');
  return new Date(parseInt(y, 10), parseInt(m, 10) - 1, parseInt(d, 10));
};

/**
 * Convert a Date object to a string
 *
 * @param {Date} date  The date object
 * @returns {string}  (dd.mm.YY) The converted date
 */
export const dateToString = (date) =>
  `${addZero(date.getDate())}.${addZero(date.getMonth() + 1)}.${date.getFullYear().toString().substring(2)}`;

/**
 * Convert a Date object to an ISO 8601 string
 *
 * @param {Date|string} date  The date as a Date or a ISO 8601 string
 * @returns {string}  (YYYY-mm-dd) The converted date
 */
export const dateToIsoString = (date) => {
  typeof date === 'string' && (date = new Date(date));
  return `${date.getFullYear().toString()}-${addZero(date.getMonth() + 1)}-${addZero(date.getDate())}`;
};

/**
 * Convert a string date-time to a Date object
 *
 * @param {string} dateTime (YYYY-mm-dd HH:ii)  The date to convert
 * @returns {Date}  The converted date
 */
export const dateTimeStringToDate = (dateTime) => {
  const [date, time] = dateTime.trim().split(' ');
  const [y, m, d] = date.split('-');
  if (time) {
    const [h, i] = time.split(':');
    return new Date(parseInt(y, 10), parseInt(m, 10) - 1, parseInt(d, 10), parseInt(h, 10), parseInt(i, 10));
  } else {
    return new Date(parseInt(y, 10), parseInt(m, 10) - 1, parseInt(d, 10));
  }
};

/**
 * Return the difference in days between two dates
 *
 * @param {Date} endDate  The end date
 * @param {Date} startDate  The start date
 * @returns {number}  The difference in days
 */
export const getDateDifferenceInDays = (endDate, startDate) =>
  Math.round((endDate - startDate) / (1000 * 60 * 60 * 24));

/**
 * Return an array of ISO 8601 dates between two dates
 *
 * @param {Date|string} startDate  The start date as a Date or a ISO 8601 string
 * @param {Date|string} endDate  The end date as a Date or a ISO 8601 string
 * @returns {string[]}  The date array
 */
export const getDaysBetweenDates = (startDate, endDate) => {
  const arr = [];
  for (const dt = new Date(startDate); dt <= new Date(endDate); dt.setDate(dt.getDate() + 1)) {
    arr.push(dateToIsoString(dt));
  }
  return arr;
};

/**
 * Add days to a date
 *
 * @param {Date|string} date  The date as a Date or a ISO 8601 string
 * @param {number} days  The number of days to add
 * @returns {Date}  The new date
 */
export const addDays = (date, days) => {
  const dt = new Date(date);
  dt.setDate(dt.getDate() + days);
  return dt;
};
