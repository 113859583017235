import PropTypes from 'prop-types';
import { useRef, Fragment } from 'react';
import { useDateRangePicker, I18nProvider } from 'react-aria';
import { useDateRangePickerState } from 'react-stately';
import { parseDate } from '@internationalized/date';
import { useTranslation } from 'react-i18next';

/** PropTypes */
import { colorPropTypes } from '../../../propTypes';

/** Utils */
import { dateToIsoString, getDaysBetweenDates, addDays } from '../../../util/Date';

/** Components */
import { DateField } from './DateTimeField';
import { RangeCalendar as BaseRangeCalendar } from '../../Calendar';
import Icon from '../../Icon';

/**
 * Custom range calendar component for selecting a date range, supporting localization.
 *
 * @component
 * @param {object} props - The component props.
 * @param {string} props.ariaLabelledby - The ID of the legend element used for accessibility.
 * @param {string} [props.ariaDescribedby] - The ID of the description element(s) used for accessibility.
 * @param {string} [props.label] - The label for the date range picker.
 * @param {object} props.currentValue - Redux state containing the start and end dates.
 * @param {string} [props.currentValue.startDate] - The start date in ISO string format.
 * @param {string} [props.currentValue.endDate] - The end date in ISO string format.
 * @param {string} [props.backgroundColor] - A background color for the calendar.
 * @param {string} [props.color='primary'] - A foreground color for the calendar.
 * @param {Function} props.onChange - Callback function triggered when the date range changes.
 * @returns {JSX.Element} The rendered component.
 */
export const RangeCalendar = ({
  ariaLabelledby,
  ariaDescribedby,
  label,
  currentValue,
  backgroundColor,
  color = 'primary',
  ...props
}) => {
  const { t: __, i18n } = useTranslation();

  let value;
  let selectedDays = [];
  if (currentValue.startDate && currentValue.endDate) {
    value = { start: parseDate(currentValue.startDate), end: parseDate(currentValue.endDate) };
    selectedDays = getDaysBetweenDates(currentValue.startDate, currentValue.endDate);
  }

  const datePickerState = useDateRangePickerState(props);
  const groupRef = useRef();
  const { labelProps, groupProps, startFieldProps, endFieldProps, calendarProps } = useDateRangePicker(
    { ...props, label, value },
    datePickerState,
    groupRef
  );

  const Background = backgroundColor ? 'div' : Fragment;

  return (
    <I18nProvider locale={i18n.language}>
      <Background {...(backgroundColor ? { className: `bg-${backgroundColor}` } : {})}>
        <BaseRangeCalendar
          {...calendarProps}
          aria-labelledby={ariaLabelledby}
          aria-describedby={ariaDescribedby}
          minValue={parseDate(dateToIsoString(addDays(new Date(), -365)))}
          maxValue={parseDate(dateToIsoString(addDays(new Date(), 365)))}
          color={color}
          onChange={(value) => {
            if (value) {
              let { start, end } = value;
              if (start && end) {
                if (end.compare(start) < 0) {
                  const temp = start;
                  start = end;
                  end = temp;
                }
                props.onChange({ startDate: start.toString(), endDate: end.toString() });
              }
            }
          }}
          onSelectionStart={() => value && props.onChange({ startDate: null, endDate: null })}
          // eventsDays={eventsDays}
          selectedDays={selectedDays}
          // disabledDays={selectedDays}
        />
      </Background>
      <div className="field date-range-field">
        <div className="field-control">
          {label && (
            <span {...labelProps} className="label">
              {label}.
            </span>
          )}
          <div {...groupProps} ref={groupRef} className="field-group">
            <DateField
              {...startFieldProps}
              label={__('form.Start date')}
              value={value && value.start}
              onChange={(date) => {
                if (date.year > 2000) {
                  props.onChange({ startDate: date.toString(), endDate: currentValue.endDate });
                }
              }}
            />
            <Icon icon="chevron-right" ariaLabel="-" />
            <DateField
              {...endFieldProps}
              label={__('form.End date')}
              value={value && value.end}
              onChange={(date) => {
                if (date.year > 2000) {
                  props.onChange({ startDate: currentValue.startDate, endDate: date.toString() });
                }
              }}
            />
          </div>
        </div>
        {datePickerState.isInvalid && <div className="field-error">{__('form.The dates are incorrect')}</div>}
      </div>
    </I18nProvider>
  );
};

RangeCalendar.propTypes = {
  ariaLabelledby: PropTypes.string.isRequired,
  ariaDescribedby: PropTypes.string,
  label: PropTypes.string,
  currentValue: PropTypes.shape({
    startDate: PropTypes.string,
    endDate: PropTypes.string,
  }).isRequired,
  backgroundColor: colorPropTypes,
  color: colorPropTypes,
  onChange: PropTypes.func.isRequired,
};

export default RangeCalendar;
