import PropTypes from 'prop-types';

/**
 * Icon component.
 *
 * @component
 * @param {object} props - The properties object.
 * @param {string} props.icon - The name of the icon. @see Icon.propTypes
 * @param {string} [props.layout] - The layout of the element. @see Icon.propTypes
 * @param {boolean} [props.ariaHidden] - Whether to hide the element for ARIA.
 * @param {string} [props.ariaLabel] - The ARIA label to set to the element.
 * @returns {JSX.Element} - The rendered component.
 */
const Icon = ({ icon, layout, ariaHidden = true, ariaLabel }) => {
  /** Props */
  const ariaProps = { 'aria-hidden': (ariaHidden && !ariaLabel) || null, 'aria-label': ariaLabel };

  /** Class names */
  const classNames = [`icon icon-${icon}`];
  layout && classNames.push(`l-${layout}`);

  return <span {...ariaProps} className={classNames.join(' ')}></span>;
};

Icon.propTypes = {
  icon: PropTypes.oneOf([
    // 'facebook',
    // 'f',
    // 'instagram',
    // 'i',
    // 'youtube',
    // 'y',
    // 'twitter',
    // 't',
    // 'x',
    // 'linkedin',
    // 'l',
    'xmark',
    'arrow-left',
    'arrow-down',
    'arrow-right',
    'chevron-down',
    'chevron-right',
    'bio',
    'certificate',
    'warehouse',
    'truck',
    'plus',
    'minus',
    'exclamation',
    'clock',
    'filters',
    'luxembourg',
    'list',
    'blank',
  ]).isRequired,
  layout: PropTypes.oneOf(['']),
  ariaHidden: PropTypes.bool,
  ariaLabel: PropTypes.string,
};

export default Icon;
