import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

/** PropTypes */
import { colorPropTypes } from '../../propTypes';

/** Redux */
import { selectPageContent, savePageData } from '../../redux/userSlice';
import { selectPage } from '../../redux/pagesSlice';

/** Hooks */
import { useMenu } from '../../hooks/useMenu';
import { useNavigateWithLoader } from '../../hooks/useNavigateWithLoader';
import { useAuth } from '../../hooks/useAuth';

/** Components */
import Button from '../Button';
import Menu from '../Menu';
import VerifyEmailForm from '../forms/VerifyEmailForm';

/**
 * RegisterMenu component that verifies that the user e-mail isn't already redistered
 * and then redirects to the registration page.
 *
 * @component
 * @param {object} props - The properties object.
 * @param {string} [props.buttonColor] - The color for the menu trigger button.
 * @returns {JSX.Element} The rendered RegisterMenu component.
 */
const RegisterMenu = ({ buttonColor = 'sage', ...props }) => {
  useAuth();
  const dispatch = useDispatch();
  const { navigate } = useNavigateWithLoader();
  const { t: __, i18n } = useTranslation();

  /** Redux */
  const pageRegister = useSelector((state) => selectPage(state, 'register', i18n.language));
  const reduxContent = useSelector((state) => selectPageContent(state, 'register'));

  /** Menu refs, state and props */
  const { menuTriggerRef, menuTriggerProps, menuRef, menuState, menuProps } = useMenu(props);

  return (
    <>
      <Button
        ref={menuTriggerRef}
        {...menuTriggerProps}
        layout="plain"
        color={buttonColor}
        className="register-menu-button"
        onPress={() => {
          reduxContent && reduxContent.email && reduxContent.email.value
            ? navigate(pageRegister.url)
            : menuState.open();
        }}
      >
        {__('button.Register')}
      </Button>
      {menuState.isOpen && (
        <Menu ref={menuRef} {...menuProps} layout="dropdown" position="over" className="register-menu">
          <VerifyEmailForm
            onVerify={(data) => {
              dispatch(savePageData({ pageName: 'register', fieldPath: 'email', value: data.email }));
              menuState.close();
              navigate(pageRegister.url);
            }}
          />
        </Menu>
      )}
    </>
  );
};

RegisterMenu.propTypes = {
  buttonColor: colorPropTypes,
};

export default RegisterMenu;
