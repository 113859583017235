import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

/** Redux */
import { isLogged } from '../redux/userSlice';

/** Utils */
import { validateFieldByType } from '../util/FormData';

// eslint-disable-next-line jsdoc/valid-types
/** @typedef {import('../components/forms/Form').Field} Field A field */

/**
 * Custom hook for form validation.
 *
 * Validates form fields based on their type and requirements. Supports nested fields
 * through group and radioGroup types. Compatible with both regular HTML forms and
 * custom field components.
 *
 * @param {Object<string, Field>} fields - Object containing field configurations.
 * @param {string} action - The form action (e.g., 'login', 'contact').
 * @returns {Function} validateForm - Callback function to validate the form.
 * @example
 * const fields = {
 *   email: {
 *     type: 'email',
 *     label: 'Email Address',
 *     isRequired: true
 *   },
 *   preferences: {
 *     type: 'group',
 *     fields: {
 *       newsletter: {
 *         type: 'checkbox',
 *         label: 'Subscribe to newsletter'
 *       }
 *     }
 *   }
 * };
 *
 * const validateForm = useFormValidation(fields, 'login');
 *
 ** In submit handler:
 * const [isValid, errors] = validateForm(formRef.current);
 */
const useFormValidation = (fields, action) => {
  const { t: __ } = useTranslation();
  const isUserLogged = useSelector(isLogged);

  /**
   * Validates all fields in a form.
   *
   * @param {HTMLFormElement|React.RefObject<HTMLFormElement>} form - The form element or ref to validate.
   * @returns {Array} result
   * @returns {boolean} result.0  Whether the form is valid.
   * @returns {object} result.1  Object mapping field names to error messages.
   */
  const validateForm = useCallback(
    (form) => {
      form = form && form.current ? form.current : form;
      const formData = new FormData(form);
      const errors = {};
      const content = {};

      if (action === 'login' && isUserLogged) {
        return [true, errors];
      }

      /**
       * Validates a single field and its nested fields if any.
       *
       * @param {string} path - The path to the field in the form structure.
       * @param {Field} field - The field configuration object.
       * @param {*} value - The current value of the field.
       * @returns {boolean} Whether the field and its nested fields are valid.
       */
      const validateField = (path, field, value) => {
        typeof value === 'string' && (value = value.trim());
        let errorMessage = '';

        // Handle group type fields
        if (field.type === 'group' && field.fields) {
          let isGroupValid = true;
          Object.entries(field.fields).forEach(([childName, childField]) => {
            const childValue = formData.get(childName);
            const isChildValid = validateField(childName, childField, childValue);
            !isChildValid && (isGroupValid = false);
          });
          if (!isGroupValid) {
            //
          }
        }

        // Handle radioGroup type fields
        if (field.type === 'radioGroup' && field.radios) {
          const selectedRadio = field.radios.find((radio) => radio.value === value);
          if (selectedRadio?.fields) {
            let isRadioGroupValid = true;
            Object.entries(selectedRadio.fields).forEach(([childName, childField]) => {
              const childValue = formData.get(childName);
              const isChildValid = validateField(childName, childField, childValue);
              !isChildValid && (isRadioGroupValid = false);
            });
            if (!isRadioGroupValid) {
              //
            }
          }
        }

        // Regular required field validation
        if (field.isRequired && (!value || value.length === 0)) {
          errorMessage = __('form.This field is required');
        }

        if (errorMessage.length === 0 && !field.skipValidation) {
          errorMessage = validateFieldByType(field.type, value, field, content, __);
        }

        content[path] = value;
        errorMessage.length > 0 && (errors[path] = errorMessage);
        return errorMessage.length === 0;
      };

      // Validate all fields
      Object.entries(fields).forEach(([name, field]) => {
        const value = formData.get(name);
        validateField(name, field, value);
      });

      const isValid = Object.keys(errors).length === 0;
      return [isValid, errors];
    },
    [action, isUserLogged, fields, __]
  );

  return validateForm;
};

export { useFormValidation };
export default useFormValidation;
