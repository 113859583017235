import { createSlice } from '@reduxjs/toolkit';
import { set } from 'lodash';
import { createSelector } from 'reselect';

/** Utils */
import { stringToDate } from '../util/Date';
import { hasProperty } from '../util/Object';

/**
 * A Redux slice for the WordPress `opportunity` post type.
 */

/**
 * @typedef {object} Opportunity - Represents an item from the opportunities list.
 * @property {number} id - The unique identifier for the list item.
 * @property {string} status - The publication status of the item (e.g., "published").
 * @property {string} type - The type of opportunity ("offer" or "request").
 * @property {object} contact - The contact details for the opportunity.
 * @property {string} contact.firstName - The first name of the contact.
 * @property {string} contact.lastName - The last name of the contact.
 * @property {string} contact.street - The street name of the contact's address.
 * @property {string} contact.number - The street number of the contact's address.
 * @property {string} contact.zip - The postal code of the contact's address.
 * @property {string} contact.city - The city of the contact's address.
 * @property {string} contact.phone - The phone number of the contact.
 * @property {object} contact.gpsCoords - The GPS coordinates of the contact's location.
 * @property {number} contact.gpsCoords.lat - The latitude coordinate.
 * @property {number} contact.gpsCoords.lng - The longitude coordinate.
 * @property {object} qualityAndOrigin - The quality and origin details of the item.
 * @property {boolean} qualityAndOrigin.biodiversityContracts - Indicates if biodiversity contracts apply.
 * @property {boolean} qualityAndOrigin.bioCertifiedFarm - Indicates if the farm is bio-certified.
 * @property {boolean} qualityAndOrigin.pesticideFree - Indicates if the item is pesticide-free.
 * @property {boolean} qualityAndOrigin.reducedFertilization - Indicates if fertilization was reduced.
 * @property {object} packagingAndSize - The packaging and size details.
 * @property {number} packagingAndSize.quantity - The quantity of the packaging.
 * @property {string} packagingAndSize.format - The format of the packaging ("squareBales", "roundBales", or "bulk").
 * @property {number} [packagingAndSize.diameter] - The diameter (required if format is "roundBales").
 * @property {string} [packagingAndSize.bulkType] - The bulk type ("bag" or "bigBag", required if format is "bulk").
 * @property {object} mowingDate - The mowing date range.
 * @property {string} mowingDate.startDate - The start date of mowing (in ISO format: YYYY-MM-DD).
 * @property {string} mowingDate.endDate - The end date of mowing (in ISO format: YYYY-MM-DD).
 * @property {number} price - The price of the opportunity (in multiples of 5).
 * @property {object} additionalOptions - Additional options for the opportunity.
 * @property {string} additionalOptions.storageType - The type of storage ("dry", "ventilated", or "outside").
 * @property {boolean} additionalOptions.delivery - Indicates if delivery is available.
 * @property {number|boolean} additionalOptions.minQuantity - The minimum quantity required, or `false` if none.
 */

/**
 * @typedef {object} Filters - The filters.
 * @property {object} opportunityType - Type of opportunity
 * @property {boolean} opportunityType.offer - Offers
 * @property {boolean} opportunityType.request - Requests
 * @property {object} qualityAndOrigin - Quality & origin
 * @property {boolean|null} qualityAndOrigin.biodiversityContracts - Biodiversity contracts
 * @property {boolean|null} qualityAndOrigin.bioCertifiedFarm - Bio certified farm
 * @property {boolean|null} qualityAndOrigin.pesticideFree - Pesticide free
 * @property {boolean|null} qualityAndOrigin.reducedFertilization - Reduced fertilization
 * @property {object} packagingAndSize - Packaging & size
 * @property {boolean|null} packagingAndSize.bulk - Bulk
 * @property {object} packagingAndSize.bulkType - Bulk: format
 * @property {boolean} packagingAndSize.bulkType.bag - Bag
 * @property {boolean} packagingAndSize.bulkType.bigBag - Big bag
 * @property {boolean|null} packagingAndSize.squareBales - Square bales
 * @property {boolean|null} packagingAndSize.roundBales - Round bales
 * @property {object} mowingDate - Mowing date
 * @property {string|null} mowingDate.startDate - Start date
 * @property {string|null} mowingDate.endDate - End date
 * @property {object} price - Price
 * @property {number|null} price.maxPerUnit - Max price per unit
 * @property {number|null} price.maxPerKilo - Max price per kilo
 * @property {object} additionalOptions - Additional filters
 * @property {boolean|null} additionalOptions.storage - Bulk
 * @property {object} additionalOptions.storageType - Storage
 * @property {boolean} additionalOptions.storageType.dry - Dry
 * @property {boolean} additionalOptions.storageType.ventilated - Ventilated
 * @property {boolean} additionalOptions.storageType.outside - Outside
 * @property {boolean|null} additionalOptions.delivery - Delivery
 */

export const opportunitiesSlice = createSlice({
  name: 'opportunities',
  initialState: {
    isLoaded: false,
    /** @type {Opportunity[]} */
    list: [],
    error: '',
    /** The opportunity/ies that has user focus. */
    focused: {
      /** @type {number[]} - On the table */
      table: [],
      /** @type {number[]} - On the map */
      map: [],
    },
    /** @type {Filters} */
    filters: {
      opportunityType: {
        offer: true,
        request: true,
      },
      qualityAndOrigin: {
        biodiversityContracts: null,
        bioCertifiedFarm: null,
        pesticideFree: null,
        reducedFertilization: null,
      },
      packagingAndSize: {
        bulk: null,
        bulkType: {
          bag: true,
          bigBag: true,
        },
        squareBales: null,
        roundBales: null,
      },
      mowingDate: {
        startDate: null,
        endDate: null,
      },
      price: {
        maxPerUnit: null,
        maxPerKilo: null,
      },
      additionalOptions: {
        storage: null,
        storageType: {
          dry: true,
          ventilated: true,
          outside: true,
        },
        delivery: null,
      },
    },
  },
  reducers: {
    /**
     * Save the list.
     *
     * @param {object} state - The redux state.
     * @param {object} action - The reducer action.
     * @param {object[]} action.payload - The list.
     */
    saveList: (state, action) => {
      state.list = action.payload;
      state.isLoaded = true;
      process.env.NODE_ENV === 'development' && console.info(state.list);
    },

    /**
     * Save the loading error.
     *
     * @param {object} state - The redux state.
     * @param {object} action - The reducer action.
     * @param {string} action.payload - The loading error.
     */
    saveError: (state, action) => {
      state.error = action.payload;
    },

    /**
     * Save the given filter.
     *
     * @param {object} state - The Redux state.
     * @param {object} action - The reducer action.
     * @param {object} action.payload - The reducer data.
     * @param {string} action.payload.group - The filter group.
     * @param {string|null} action.payload.field - The filter name.
     * @param {boolean|object} action.payload.value - The filter value.
     */
    saveFilter: (state, { payload: { group, field, value } }) => {
      process.env.NODE_ENV === 'development' && console.info('saveFilter', group, field, value);
      if (group.includes('.')) {
        set(state.filters, `${group}.${field}`, value);
      } else {
        if (field !== null) {
          state.filters[group][field] = value;
        } else {
          state.filters[group] = value;
        }
      }
    },

    /**
     * Save the focused opportunity ID.
     *
     * @param {object} state  The redux state.
     * @param {object} action  The reducer action.
     * @param {object} action.payload - The reducer data.
     * @param {'table'|'map'} action.payload.type - The focused type.
     * @param {number[]} action.payload.ids - The opportunities ids.
     */
    saveFocused: (state, { payload: { type, ids } }) => {
      state.focused[type] = ids;
    },
  },
});

export const { saveList, saveError, saveFilter, saveFocused } = opportunitiesSlice.actions;

/**
 * Filters a list item by a given language.
 *
 * @param {object} listItem - The current list item to filter.
 * @param {string} language - The 2-letter language code.
 * @returns {boolean} Whether the list item matches the language filter.
 */
const filterByLanguage = (listItem, language) => !hasProperty(listItem, 'lang') || listItem.lang === language;

/**
 * Filters a list item based on opportunity type.
 *
 * @param {object} groupValue - The filter group for opportunity types.
 * @param {boolean} groupValue.offer - Whether "offer" type is enabled.
 * @param {boolean} groupValue.request - Whether "request" type is enabled.
 * @param {object} listItem - The current list item to filter.
 * @param {string} listItem.type - The type of opportunity ("offer" or "request").
 * @returns {boolean} Whether the list item matches the opportunity type filter.
 */
const filterOpportunityType = (groupValue, listItem) =>
  Object.entries(groupValue)
    .map(
      ([filterName, filterValue]) =>
        (listItem.type === filterName && filterValue === true) || listItem.type !== filterName
    )
    .every((value) => value === true);

/**
 * Filters items based on quality and origin criteria.
 *
 * @param {object} groupValue - The filter group for quality and origin.
 * @param {object} listItem - The current list item to filter.
 * @param {object} listItem.qualityAndOrigin - The item's quality and origin properties.
 * @returns {boolean} Whether the list item matches all of the quality and origin filter.
 */
const filterQualityAndOrigin = (groupValue, listItem) =>
  Object.entries(groupValue)
    .map(
      ([filterName, filterValue]) =>
        (filterValue === null ? null : listItem.qualityAndOrigin[filterName] === filterValue) // prettier-ignore
    )
    .filter((value) => value !== null)
    .every((value) => value === true);

/**
 * Filters items based on packaging and size criteria.
 *
 * @param {object} groupValue - The filter group for packaging and size.
 * @param {boolean|null} groupValue.squareBales - Whether square bales are enabled.
 * @param {boolean|null} groupValue.roundBales - Whether round bales are enabled.
 * @param {boolean|null} groupValue.bulk - Whether bulk is enabled.
 * @param {object} groupValue.bulkType - Bulk type details.
 * @param {boolean} groupValue.bulkType.bag - Whether "bag" type is enabled.
 * @param {boolean} groupValue.bulkType.bigBag - Whether "bigBag" type is enabled.
 * @param {object} listItem - The current list item to filter.
 * @param {object} listItem.packagingAndSize - The item's packaging and size details.
 * @returns {boolean} Whether the list item matches the packaging and size filter.
 */
const filterPackagingAndSize = (groupValue, listItem) => {
  switch (listItem.packagingAndSize.format) {
    case 'squareBales':
      return (
        groupValue.squareBales === true ||
        (groupValue.squareBales === null && groupValue.roundBales !== true && groupValue.bulk !== true)
      );
    case 'roundBales':
      return (
        groupValue.roundBales === true ||
        (groupValue.roundBales === null && groupValue.squareBales !== true && groupValue.bulk !== true)
      );
    case 'bulk':
      return (
        (groupValue.bulk === true && groupValue.bulkType[listItem.packagingAndSize.bulkType] === true) ||
        (groupValue.bulk === null && groupValue.squareBales !== true && groupValue.roundBales !== true)
      );
    default:
      return true;
  }
};

/**
 * Filters items based on mowing date range.
 *
 * @param {object} groupValue - The filter group for mowing dates.
 * @param {string|null} groupValue.startDate - The filter start date (ISO string).
 * @param {string|null} groupValue.endDate - The filter end date (ISO string).
 * @param {object} listItem - The current list item to filter.
 * @param {object} listItem.mowingDate - The item's mowing date object.
 * @param {string} listItem.mowingDate.startDate - The item's start date (ISO string).
 * @param {string} listItem.mowingDate.endDate - The item's end date (ISO string).
 * @returns {boolean} Whether the list item matches the mowing date filter.
 */
const filterMowingDate = (groupValue, listItem) => {
  if (groupValue.startDate === null || groupValue.endDate === null) {
    return true;
  }
  const startDate = stringToDate(listItem.mowingDate.startDate);
  const endDate = stringToDate(listItem.mowingDate.endDate);
  const filterStartDate = stringToDate(groupValue.startDate);
  const filterEndDate = stringToDate(groupValue.endDate);
  return (
    (startDate >= filterStartDate && startDate <= filterEndDate) ||
    (endDate >= filterStartDate && endDate <= filterEndDate) ||
    (startDate <= filterStartDate && endDate >= filterEndDate)
  );
};

/**
 * Filters items based on price criteria.
 *
 * @param {object} groupValue - The filter group for price.
 * @param {number|null} groupValue.maxPerUnit - Maximum price per unit.
 * @param {number|null} groupValue.maxPerKilo - Maximum price per kilo.
 * @param {object} listItem - The current list item to filter.
 * @param {object} listItem.packagingAndSize - The item's packaging and size details.
 * @param {string} listItem.packagingAndSize.format - The format type ("squareBales", "roundBales", or "bulk").
 * @param {number} listItem.price - The item's price.
 * @returns {boolean} Whether the list item matches the price filter.
 */
const filterPrice = (groupValue, listItem) => {
  const format = listItem.packagingAndSize.format;
  if (format === 'squareBales' || format === 'roundBales') {
    return groupValue.maxPerUnit === null || groupValue.maxPerUnit >= listItem.price;
  } else if (format === 'bulk') {
    return groupValue.maxPerKilo === null || groupValue.maxPerKilo >= listItem.price;
  }
  return true;
};

/**
 * Filters items based on additional options.
 *
 * @param {object} groupValue - The filter group for additional options.
 * @param {boolean|null} groupValue.storage - Whether storage filter is enabled.
 * @param {object} groupValue.storageType - Storage type details.
 * @param {boolean} groupValue.storageType.dry - Whether "dry" storage is enabled.
 * @param {boolean} groupValue.storageType.ventilated - Whether "ventilated" storage is enabled.
 * @param {boolean} groupValue.storageType.outside - Whether "outside" storage is enabled.
 * @param {boolean|null} groupValue.delivery - Whether delivery filter is enabled.
 * @param {object} listItem - The current list item to filter.
 * @param {object} listItem.additionalOptions - The item's additional options.
 * @returns {boolean} Whether the list item matches the additional options filter.
 */
const filterAdditionalOptions = (groupValue, listItem) => {
  return [
    !groupValue.storage ||
      Object.entries(groupValue.storageType)
        .map(([type, value]) => (value === true ? type : null))
        .filter((value) => value !== null)
        .includes(listItem.additionalOptions.storageType),
    groupValue.delivery === null || listItem.additionalOptions.delivery === groupValue.delivery,
  ].every((value) => value === true);
};

/**
 * Applies the Redux filters to an item from the opportunities list.
 *
 * @param {Filters} filters - The Redux filters.
 * @param {Opportunity} listItem - An item from the opportunities list.
 * @returns {boolean} True if the list item passes all active filters, otherwise false.
 */
const applyFilters = (filters, listItem) => {
  const returnValue = Object.entries(filters)
    .map(([groupName, groupValue]) => {
      switch (groupName) {
        case 'opportunityType':
          return filterOpportunityType(groupValue, listItem);
        case 'qualityAndOrigin':
          return filterQualityAndOrigin(groupValue, listItem);
        case 'packagingAndSize':
          return filterPackagingAndSize(groupValue, listItem);
        case 'mowingDate':
          return filterMowingDate(groupValue, listItem);
        case 'price':
          return filterPrice(groupValue, listItem);
        case 'additionalOptions':
          return filterAdditionalOptions(groupValue, listItem);
        default:
          return true;
      }
    })
    .every((value) => value === true);
  return returnValue;
};

/**
 * Return the opportunities list.
 *
 * @returns {Opportunity[]} The list.
 */
export const selectList = createSelector(
  (state) => state.opportunities,
  (opportunities) => opportunities.list
);

/**
 * Return the opportunities list filtered by a given language.
 *
 * @param {string} language - The desired language.
 * @returns {Opportunity[]} The filtered list.
 */
export const selectListByLang = createSelector(
  (state) => state.opportunities,
  (_, language) => language,
  (opportunities, language) => opportunities.list.filter((opportunity) => filterByLanguage(opportunity, language))
);

/**
 * Return the opportunities list with filters applied.
 *
 * @returns {Opportunity[]} The filtered list.
 */
export const selectFilteredList = createSelector(
  (state) => state.opportunities,
  (opportunities) => opportunities.list.filter((opportunity) => applyFilters(opportunities.filters, opportunity))
);

/**
 * Return the opportunities list with filters applied, in a given language.
 *
 * @param {string} language - The desired language.
 * @returns {Opportunity[]} The filtered list.
 */
export const selectFilteredListByLang = createSelector(
  (state) => state.opportunities,
  (_, language) => language,
  (opportunities, language) => {
    return opportunities.list
      .filter((opportunity) => filterByLanguage(opportunity, language))
      .filter((opportunity) => applyFilters(opportunities.filters, opportunity));
  }
);

/**
 * Checks if any filters are currently applied.
 *
 * A filter is considered active if:
 * - It is not `null`.
 * - It is not an object (to skip nested filter groups).
 *
 * Special handling is applied to the `opportunityType` filter group, where
 * a filter is active if either `offer` or `request` is explicitly set to `false`.
 *
 * @returns {boolean} - Returns `true` if at least one filter is active, otherwise `false`.
 */
export const selectHasFiltersApplied = createSelector(
  (state) => state.opportunities,
  (opportunities) =>
    Object.entries(opportunities.filters)
      .map(([groupName, groupValue]) => {
        return groupName === 'opportunityType'
          ? groupValue.offer === false || groupValue.request === false
          : Object.values(groupValue)
              .map((filterValue) => filterValue !== null && typeof filterValue !== 'object')
              .some((value) => value === true);
      })
      .some((value) => value === true)
);

/**
 * Selector to get the maximum price from the opportunities list for one or more specified packaging formats.
 *
 * @param {string|string[]} formats - The desired packaging format(s) ("bulk", "squareBales", or "roundBales").
 * @returns {number} - The maximum price of items with the specified format(s), or 0 if no matching items exist.
 */
export const selectMaxPrice = createSelector(
  (state) => state.opportunities,
  (_, formats) => formats,
  (opportunities, formats) => {
    const formatArray = Array.isArray(formats) ? formats : [formats];
    return opportunities.list
      .filter((item) => formatArray.includes(item.packagingAndSize.format))
      .reduce((max, item) => (item.price > max ? item.price : max), 0);
  }
);

/**
 * Return the focused opportunities ID's.
 *
 * @returns {number[]} The focused opportunities ID's.
 */
export const selectFocused = createSelector(
  (state) => state.opportunities,
  (opportunities) => opportunities.focused
);

export default opportunitiesSlice.reducer;
