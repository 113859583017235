import PropTypes from 'prop-types';
import { useEffect, useState, lazy, Suspense, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

/** PropTypes */
import { pagePropTypes } from './propTypes';

/** Redux */
import { saveCurrentPage, saveIsContentLoaded } from './redux/appSlice';
import { saveList as savePagesList } from './redux/pagesSlice';
import { saveList as saveOpportunitiesList } from './redux/opportunitiesSlice';
import { saveList as saveCitiesList } from './redux/citiesSlice';
import { saveOptions } from './redux/optionsSlice';
import { isLogged, saveUser, deleteUser } from './redux/userSlice';

/** Utils */
import { fetchAndParseJSON } from './util/Fetch';
import { getCookie, deleteCookie } from './util/Cookies';

/** Hooks */
import { useCustomResize } from './hooks/useCustomResize';
import { useScrollbarWidth } from './hooks/useScrollbarWidth';
import { useMeta } from './hooks/useMeta';
import { useNavigateWithLoader } from './hooks/useNavigateWithLoader';
import { useFetchUserPages } from './hooks/useFetchUserPages';

/** Components */
import Header from './components/layouts/Header';
import Footer from './components/layouts/Footer';
import Loader from './components/Loader';
import Page404 from './components/templates/Page404';

/** Styles */
import './fonts/icomoon.scss';
import './App.scss';

/**
 * @typedef {object} Page - A page.
 * @property {number} id - The id of the page.
 * @property {string} url - The url of the page.
 * @property {string} restUrl - The rest url of the page.
 * @property {string} title - The title of the page.
 * @property {string} templateName - The name of the template for the page.
 * @property {string} pageName - A slug for the page, regardless the language.
 * @property {number} parentId - The id of the parent of the page.
 * @property {string} lang - The 2-letter language code of the page.
 * @property {object} translations - An object of translations for the page {'language_code': 'url'}.
 * @property {'page'|'404'|''} type - The type of a page -- Added by <AppRouter />.
 * @property {Array|object} data - Additional data -- The type is array when there is no data.
 */

/**
 * App.
 *
 * The main application component that fetches the app content from the REST api, feeds the Redux store and
 * sets up the browser router.
 *
 * @component
 * @returns {JSX.Element} - The rendered component.
 */
const App = () => {
  process.env.NODE_ENV === 'development' && console.info('<App />');

  const dispatch = useDispatch();
  const { t: __ } = useTranslation();
  const fetchUserPages = useFetchUserPages();

  useCustomResize();
  useScrollbarWidth();

  const isPagesListLoaded = useSelector((state) => state.pages.isLoaded);
  const isUserPagesListLoaded = useSelector((state) => state.user.pages.isLoaded);

  /** Fetch the user data from the user cookie and the user pages if the user is logged */
  useEffect(() => {
    const userCookie = getCookie(process.env.REACT_APP_USER_COOKIE);
    if (userCookie) {
      const user = JSON.parse(userCookie);
      if (user.isLoggedIn) {
        if (window.location.hash === '#login') {
          /** Log out the user. */
          deleteCookie(process.env.REACT_APP_USER_COOKIE);
          dispatch(deleteUser());
        } else {
          dispatch(saveUser(user));
          !isUserPagesListLoaded && fetchUserPages(user);
        }
      }
    }
  }, [dispatch, fetchUserPages, isUserPagesListLoaded]);

  /** Fetch the pages & options */
  useEffect(() => {
    Promise.all([
      fetchAndParseJSON(`${process.env.REACT_APP_REST_URL}pages`),
      fetchAndParseJSON(`${process.env.REACT_APP_REST_URL}opportunities`),
      fetchAndParseJSON(`${process.env.REACT_APP_REST_URL}cities`),
      fetchAndParseJSON(`${process.env.REACT_APP_REST_URL}options`),
    ])
      .then(([pages, opportunities, cities, options]) => {
        dispatch(savePagesList(pages));
        dispatch(saveOpportunitiesList(opportunities));
        dispatch(saveCitiesList(cities));
        dispatch(saveOptions(options));
        return;
      })
      .catch(console.error);
  }, [dispatch]);

  /** Add development tools */
  const DevTools = process.env.NODE_ENV === 'development' ? lazy(() => import('./Dev')) : <></>;

  return (
    <>
      <Loader />
      <BrowserRouter>
        {isPagesListLoaded && (
          <>
            {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
            <a href="#main" aria-label={__('app.Skip to content')} className="visually-hidden-focusable" />
            <Header />
            <AppRouter />
            <Footer />
          </>
        )}
      </BrowserRouter>
      <Suspense fallback={<></>}>{process.env.NODE_ENV === 'development' && <DevTools />}</Suspense>
    </>
  );
};

/**
 * AppRouter.
 *
 * Handles the routing of the application by defining routes dynamically based on pages and user data fetched from
 * Redux. Renders a 404 page for unmatched routes.
 *
 * @component
 * @returns {JSX.Element} - The rendered component.
 */
const AppRouter = () => {
  process.env.NODE_ENV === 'development' && console.info('<AppRouter />');

  /** Redux */
  const pagesList = useSelector((state) => state.pages.list);
  const userPagesList = useSelector((state) => state.user.pages.list);

  /** User */
  const isUserLogged = useSelector(isLogged);
  const isUserPagesListLoaded = useSelector((state) => state.user.pages.isLoaded);

  /** Routes */
  const routes = useMemo(
    () => [
      { type: 'page', pages: pagesList },
      { type: 'user', pages: userPagesList },
    ],
    [pagesList, userPagesList]
  );

  return (
    <Routes>
      {routes.map(({ type, pages }) =>
        pages.map((page, index) => (
          <Route key={`${type}-${index}`} path={page.url} element={<AppRoute page={{ ...page, type }} />} />
        ))
      )}
      {(!isUserLogged || isUserPagesListLoaded) && <Route path="*" element={<Page404 />} />}
    </Routes>
  );
};

/**
 * AppRoute.
 *
 * Dynamically renders a page's template and content by lazy-loading the appropriate component and fetching the
 * required data from the REST API.
 * Handles metadata updates and manages content loading states.
 *
 * @component
 * @param {Page} page - The current page.
 * @returns {JSX.Element} - The rendered component.
 */
const AppRoute = ({ page }) => {
  // process.env.NODE_ENV === 'development' && console.info('<AppRoute />', page);

  const dispatch = useDispatch();
  const setMeta = useMeta();
  const { removeLoader } = useNavigateWithLoader();
  const { i18n } = useTranslation();

  const currentPage = useSelector((state) => state.app.currentPage);
  const [Template, setTemplate] = useState(null);
  const [templateContent, setTemplateContent] = useState(null);
  const [pageContent, setPageContent] = useState(null);

  /** User */
  const isUserLogged = useSelector(isLogged);
  const user = useSelector((state) => state.user);

  useEffect(() => {
    if (page !== currentPage) {
      /** Show the loader */
      dispatch(saveIsContentLoaded(false));

      /** Reset the state */
      setTemplate(null);
      setTemplateContent(null);

      /** Lazy load the template */
      setTemplate(lazy(() => import(`./components/templates/${page.templateName}`)));

      /** Fetch the template content */
      fetchAndParseJSON(page.restUrl, {
        'X-APP-LANGUAGE': i18n.language,
        ...(isUserLogged && { 'X-APP-USER': encodeURIComponent(window.btoa(`${user.email}:${user.token}`)) }),
      })
        .then((response) => {
          process.env.NODE_ENV === 'development' && console.info('<AppRoute /> - Page content fetched');

          /** Save the current page to Redux */
          dispatch(saveCurrentPage(page));

          /** Update the state */
          setTemplateContent(response);
          setPageContent(page);

          /** Set the meta data of the page */
          setMeta({ ...response.meta, url: page.url, lang: i18n.language });

          /** Remove the loader */
          removeLoader();
          return;
        })
        .catch(console.error);
    }
  }, [page, currentPage, dispatch, setTemplateContent, setMeta, i18n.language, removeLoader, user, isUserLogged]);

  return (
    <Suspense fallback={<></>}>
      <>{Template && templateContent && <Template page={pageContent} content={templateContent} />}</>
    </Suspense>
  );
};

AppRoute.propTypes = {
  /** The page data */
  page: PropTypes.shape(pagePropTypes).isRequired,
};

export default App;
