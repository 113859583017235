import PropTypes from 'prop-types';
import { useEffect, useMemo, forwardRef } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { kebabCase } from 'lodash';

/** PropTypes */
import { pagePropTypes, colorPropTypes, linkPropTypes } from '../../propTypes';

/** Hooks */
import { useOnLoad } from '../../hooks/useOnLoad';

/** Redux */
import { selectPage } from '../../redux/pagesSlice';

/** Utils */
import { formatText } from '../../util/String';

/** Components */
import LoginRegister from '../LoginRegister';
import Link from '../Link';
import logoHeuborse from '../../images/logo-heuborse-outline.png';

/** PropTypes */
const commonPropTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

/**
 * Template layout.
 *
 * @component
 * @param {object} props - The properties object.
 * @param {object} props.page - The current page. @see pagePropTypes
 * @param {string} props.page.templateName - The name of the template.
 * @param {string} props.page.pageName - The name (slug) of the page.
 * @param {string} [props.layout] - The layout of the element. @see Template.propTypes
 * @param {string} [props.color] - The of the element's background.
 * @param {string} [props.className] - The class names to add to the element.
 * @param {JSX.Element} props.children - The children of the element.
 * @returns {JSX.Element} - The rendered component.
 */
export const Template = ({ page: { templateName, pageName }, layout, color, className = '', children }) => {
  process.env.NODE_ENV === 'development' && console.info(`<Template /> - ${templateName}`);

  /** Fire a "template-load" event */
  const onLoad = useOnLoad('template-');
  useEffect(() => onLoad());

  /** Class names */
  const classNames = useMemo(() => {
    const classes = [`template template-${kebabCase(templateName)} page-${pageName}`];
    layout && classes.push(`l-${layout}`);
    color && classes.push(`bg-${color}`);
    className && classes.push(className);
    return classes.join(' ');
  }, [templateName, pageName, layout, color, className]);

  return (
    <main id="main" className={classNames}>
      {children}
    </main>
  );
};

Template.propTypes = {
  ...commonPropTypes,
  page: PropTypes.shape(pagePropTypes).isRequired,
  layout: PropTypes.oneOf(['']),
  color: colorPropTypes,
};

/**
 * TemplateTitle layout.
 *
 * @component
 * @param {object} props - The properties object.
 * @param {string} [props.tag='h2'] - The tag to apply to the element.
 * @param {string} [props.className] - The class names to add to the element.
 * @param {JSX.Element} [props.children] - The children of the element.
 * @returns {JSX.Element} - The rendered component.
 */
export const TemplateTitle = ({ tag: Tag = 'h2', className = '', children }) => (
  <Tag className={`template-title ${className}`}>{children}</Tag>
);

TemplateTitle.propTypes = {
  ...commonPropTypes,
  tag: PropTypes.oneOf(['h2', 'h3', 'h4', 'h5', 'h6', 'p']),
};

/**
 * @typedef {object} TemplateHeaderData - The content of the template header from the api.
 * @property {string} title - The title of the header.
 * @property {string} [text] - The text of the header.
 * @property {string} [link] - A link to append to the header.
 * @property {string} [color] - The background color of the header to override the default color.
 */

/**
 * TemplateHeader layout.
 *
 * @component
 * @param {object} props - The properties object.
 * @param {'header'|'div'} [props.tag='header'] - The tag to apply to the element.
 * @param {string} [props.color] - The color of the element's background.
 * @param {string} [props.className] - The class names to add to the element.
 * @param {TemplateHeaderData} props.data - The content of the template header from the api.
 * @param {boolean} props.hasLogin - Whether to display the login / register menus.
 * @returns {JSX.Element} - The rendered component.
 */
export const TemplateHeader = ({ tag: Tag = 'header', color = 'sage', className = '', data, hasLogin = false }) => {
  const { title, text, link, color: colorOverride } = data;
  const { t: __, i18n } = useTranslation();

  /** Redux */
  const frontPage = useSelector((state) => selectPage(state, 'frontpage', i18n.language));

  /** Class names */
  const classNames = useMemo(() => {
    const classes = ['template-header'];
    (colorOverride || color) && classes.push(`bg-${colorOverride || color}`);
    className && classes.push(className);
    title && classes.push('has-data');
    text && classes.push('has-text');
    link && classes.push('has-link');
    return classes.join(' ');
  }, [colorOverride, color, className, title, text, link]);

  return (
    data &&
    title && (
      <Tag className={classNames}>
        <h1>
          <Link url={frontPage.url}>
            <img className="template-header-logo" src={logoHeuborse} alt={__('app.siteName')} />
          </Link>
        </h1>
        <div>
          <TemplateTitle>
            <span dangerouslySetInnerHTML={{ __html: formatText(title) }} />
          </TemplateTitle>
          {text && <div className="template-header-text" dangerouslySetInnerHTML={{ __html: formatText(text) }} />}
        </div>
        {link && (
          <div className="template-header-link">
            <Link
              layout="button-plain"
              color={colorOverride || color}
              url={link.url}
              title={link.title}
              target={link.target}
            />
          </div>
        )}
        {hasLogin && <LoginRegister layout="fixed" />}
      </Tag>
    )
  );
};

TemplateHeader.propTypes = {
  tag: PropTypes.oneOf(['header', 'div']),
  color: colorPropTypes,
  className: PropTypes.string,
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    text: PropTypes.string,
    link: PropTypes.shape(linkPropTypes),
    color: colorPropTypes,
  }).isRequired,
  hasLogin: PropTypes.bool,
};

/**
 * TemplateContent layout, whose ref is forwarded.
 *
 * @component
 * @param {object} props - The properties object.
 * @param {string} [props.className] - The class names to add to the element.
 * @param {JSX.Element} props.children - The children of the element.
 * @param {boolean} props.hasFrontPageLink - Whether to dislay a link to the front page at the bottom.
 * @returns {JSX.Element} - The rendered component.
 */
export const TemplateContent = forwardRef(({ className = '', children, hasFrontPageLink = false }, contentRef) => {
  const { t: __, i18n } = useTranslation();
  const frontPage = useSelector((state) => selectPage(state, 'frontpage', i18n.language));

  return (
    <div ref={contentRef} className={`template-content ${className}`}>
      {children}
      {hasFrontPageLink && (
        <p className="front-page-link">
          <Link layout="button-outline" url={frontPage.url} title={__('template.404.Back to the front page')} />
        </p>
      )}
    </div>
  );
});

TemplateContent.displayName = 'TemplateContent';
TemplateContent.propTypes = {
  ...commonPropTypes,
  hasFrontPageLink: PropTypes.bool,
};

/**
 * TemplateFooter layout.
 *
 * @component
 * @param {object} props - The properties object.
 * @param {string} [props.tag='footer'] - The tag to apply to the element.
 * @param {string} [props.className] - The class names to add to the element.
 * @param {JSX.Element} props.children - The children of the element.
 * @returns {JSX.Element} - The rendered component.
 */
export const TemplateFooter = ({ tag: Tag = 'footer', className = '', children }) => (
  <Tag className={`template-footer ${className}`}>{children}</Tag>
);

TemplateFooter.propTypes = {
  ...commonPropTypes,
  tag: PropTypes.oneOf(['header', 'div']),
};

export default Template;
