/**
 * @file Calendar.jsx
 * @description Provides calendar components, including a calendar grid, header, and individual cells for single-date or range-based selection.
 */

import PropTypes from 'prop-types';
import { useMemo, useRef, useEffect } from 'react';
import { useLocale, useCalendar, useRangeCalendar, useCalendarCell, useCalendarGrid } from 'react-aria';
import { useCalendarState, useRangeCalendarState } from 'react-stately';
import {
  createCalendar,
  isSameDay,
  isSameMonth,
  getWeeksInMonth,
  getDayOfWeek,
  getLocalTimeZone,
  parseDate,
} from '@internationalized/date';

/** PropTypes */
import { colorPropTypesNoWhite } from '../propTypes';

/** Utils */
import { addDays, getDateDifferenceInDays, dateToIsoString } from '../util/Date';

/** Components */
import Button from './Button';

/**
 * Common prop types for calendar days.
 *
 * @typedef {object} CalendarDaysProps
 * @property {string[]} [eventsDays] - An array of ISO 8601 dates (YYYY-MM-DD) where events occur.
 * @property {string[]} [selectedDays] - An array of ISO 8601 dates (YYYY-MM-DD) that are selected.
 * @property {string[]} [disabledDays] - An array of ISO 8601 dates (YYYY-MM-DD) that are disabled.
 * @property {Array<{day: string, label: string}>} [labels] - An array of objects associating an ISO 8601 date with a label.
 */
const calendarDaysProps = {
  eventsDays: PropTypes.arrayOf(PropTypes.string),
  selectedDays: PropTypes.arrayOf(PropTypes.string),
  disabledDays: PropTypes.arrayOf(PropTypes.string),
  labels: PropTypes.arrayOf(
    PropTypes.shape({
      day: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
};

/**
 * Common prop types for calendar components.
 *
 * @typedef {object} CalendarPropTypes
 * @augments CalendarDaysProps
 * @property {string} [color] - The color of the calendar elements.
 */
export const calendarPropTypes = {
  ...calendarDaysProps,
  color: colorPropTypesNoWhite,
};

/**
 * Calendar component for displaying a single-month view with customizable days and events.
 *
 * @component
 * @param {CalendarPropTypes} props - The properties object.
 * @returns {JSX.Element} The rendered component.
 */
export const Calendar = ({ color, eventsDays, selectedDays, disabledDays, labels, ...props }) => {
  const { locale } = useLocale();

  const firstSelectedDate = selectedDays.length > 0 ? parseDate(selectedDays[0]) : undefined;
  const calendarState = useCalendarState({ ...props, locale, createCalendar, defaultFocusedValue: firstSelectedDate });
  const { calendarProps, prevButtonProps, nextButtonProps, title } = useCalendar(props, calendarState);
  const daysProps = { eventsDays, selectedDays, disabledDays, labels };

  /** Class names */
  const classNames = ['calendar'];
  color && classNames.push(`c-${color}`);

  return (
    <div {...calendarProps} className={classNames.join(' ')}>
      <CalendarHeader title={title} prevButtonProps={prevButtonProps} nextButtonProps={nextButtonProps} />
      <CalendarGrid state={calendarState} {...daysProps} />
    </div>
  );
};

Calendar.propTypes = calendarPropTypes;

/**
 * RangeCalendar component for displaying a range of selectable dates.
 *
 * @component
 * @param {CalendarPropTypes} props - The properties object.
 * @returns {JSX.Element} The rendered component.
 */
export const RangeCalendar = ({ color, eventsDays, selectedDays, disabledDays, labels, ...props }) => {
  const { locale } = useLocale();

  const firstSelectedDate = selectedDays.length > 0 ? parseDate(selectedDays[0]) : undefined;
  const calendarRef = useRef(null);
  const calendarState = useRangeCalendarState({
    ...props,
    locale,
    createCalendar,
    defaultFocusedValue: firstSelectedDate,
  });
  const { calendarProps, prevButtonProps, nextButtonProps, title } =
    useRangeCalendar(props, calendarState, calendarRef); //prettier-ignore
  const daysProps = { eventsDays, selectedDays, disabledDays, labels };

  /** Detect when selection starts. */
  useEffect(() => {
    if (calendarState.anchorDate && !calendarState.value) {
      props.onSelectionStart && props.onSelectionStart();
    }
  }, [calendarState.anchorDate, calendarState.value, props]);

  /** Class names */
  const classNames = ['calendar range-calendar'];
  color && classNames.push(`c-${color}`);

  return (
    <div {...calendarProps} ref={calendarRef} className={classNames.join(' ')}>
      <CalendarHeader title={title} prevButtonProps={prevButtonProps} nextButtonProps={nextButtonProps} />
      <CalendarGrid state={calendarState} {...daysProps} />
    </div>
  );
};

RangeCalendar.propTypes = {
  ...calendarPropTypes,
  onSelectionStart: PropTypes.func,
};

/**
 * CalendarHeader component for the calendar, displaying the title and navigation buttons.
 *
 * @component
 * @param {object} props - The properties object.
 * @param {string} props.title - The title of the calendar (e.g., "January 2024").
 * @param {object} props.prevButtonProps - Props for the "previous" button.
 * @param {object} props.nextButtonProps - Props for the "next" button.
 * @returns {JSX.Element} The rendered component.
 */
export const CalendarHeader = ({ title, prevButtonProps, nextButtonProps }) => (
  <div className="calendar-header">
    <Button {...prevButtonProps} icon="arrow-left" className="l-prev" />
    <h3 className="h6">{title}</h3>
    <Button {...nextButtonProps} icon="arrow-right" className="l-next" />
  </div>
);

CalendarHeader.propTypes = {
  /** The month and the year */
  title: PropTypes.string.isRequired,
  /** Props for the previous button */
  prevButtonProps: PropTypes.object.isRequired,
  /** Props for the next button */
  nextButtonProps: PropTypes.object.isRequired,
};

/**
 * CalendarGrid component for displaying the calendar days.
 *
 * @component
 * @param {object} props - The properties object.
 * @param {object} props.state - The state object from React Aria's calendar hooks. @see https://react-spectrum.adobe.com/react-stately/useCalendarState.html#api
 * @returns {JSX.Element} The rendered component.
 */
export const CalendarGrid = ({ state, ...props }) => {
  const { locale } = useLocale();
  const { gridProps, headerProps, weekDays } = useCalendarGrid(props, state);

  /** Get the number of weeks in the month so we can render the proper number of rows */
  const weeksInMonth = getWeeksInMonth(state.visibleRange.start, locale);

  return (
    <table {...gridProps}>
      <thead {...headerProps}>
        <tr>
          {weekDays.map((day, index) => (
            <th key={index}>{day}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {[...new Array(weeksInMonth).keys()].map((weekIndex) => (
          <tr key={weekIndex}>
            {state.getDatesInWeek(weekIndex).map((date, i) => {
              return date ? <CalendarCell key={i} state={state} date={date} {...props} /> : <td key={i} />;
            })}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

CalendarGrid.propTypes = {
  ...calendarDaysProps,
  state: PropTypes.object.isRequired,
};

/**
 * CalendarCell component for displaying a single calendar cell.
 *
 * The state object contains an array of dates in the week index counted from the provided start date,
 * or the first visible date if not given. The returned array always has 7 elements, but may include
 * null if the date does not exist according to the calendar system. @see https://react-spectrum.adobe.com/react-stately/useCalendarState.html#api
 *
 * @component
 * @param {CalendarDaysProps} props - The properties object.
 * @param {object} props.state - The state object for the calendar.
 * @param {object} props.date - The date object representing this cell.
 * @returns {JSX.Element} The rendered component.
 */
const CalendarCell = ({ state, date, eventsDays = [], selectedDays = [], disabledDays = [], labels = [] }) => {
  const { locale } = useLocale();
  const localTimeZone = getLocalTimeZone();

  /** Disable cells when there are no events and select cells when there are selected days */
  const [isDisabledByProps, isSelectedByProps] = useMemo(
    () => [
      (disabledDays.length > 0 && disabledDays.filter((day) => day === date.toString()).length > 0) ||
        (eventsDays.length > 0 && eventsDays.filter((day) => day === date.toString()).length === 0),
      selectedDays.length > 0 && selectedDays.filter((day) => day === date.toString()).length > 0,
    ],
    [date, eventsDays, selectedDays, disabledDays]
  );

  /** Cell props */
  const cellRef = useRef(null);
  const {
    cellProps,
    buttonProps,
    isSelected: isSelectedByState,
    isOutsideVisibleRange,
    isDisabled: isDisabledByState,
    isUnavailable,
    formattedDate,
  } = useCalendarCell({ date }, state, cellRef);

  /** Selected & disabled state */
  const [isSelected, isDisabled] = useMemo(
    () => [isSelectedByProps || isSelectedByState, isDisabledByProps || isDisabledByState],
    [isSelectedByProps, isSelectedByState, isDisabledByProps, isDisabledByState]
  );

  /** Disable cells when they're disabled or selected (by the props) */
  if (isDisabledByProps || isSelectedByProps) {
    cellProps['aria-disabled'] = true;
    buttonProps['aria-disabled'] = true;
    buttonProps.onPointerDown = () => {};
  }

  /** Add a label if there is one in the labels array */
  const label = labels.filter((label) => label.day === date.toString())[0];
  label && !isOutsideVisibleRange && (cellProps['aria-label'] = label.label);

  const isSelectionStart = state.highlightedRange ? isSameDay(date, state.highlightedRange.start) : isSelected;
  const isSelectionEnd = state.highlightedRange ? isSameDay(date, state.highlightedRange.end) : isSelected;

  /** Handle the rounded corners of the cells */
  const roundedCorners = useMemo(() => {
    const dayOfWeek = getDayOfWeek(date, locale);
    const daysInMonth = date.calendar.getDaysInMonth(date);

    let corners = { topLeft: false, topRight: false, bottomRight: false, bottomLeft: false };
    if (isSelected) {
      corners = { topLeft: true, topRight: true, bottomRight: true, bottomLeft: true };

      if (isSelectedByProps) {
        const prevDay = dateToIsoString(addDays(date.toString(), -1));
        const nextDay = dateToIsoString(addDays(date.toString(), 1));
        const sameDayPrevWeek = dateToIsoString(addDays(date.toString(), -7));
        const sameDayNextWeek = dateToIsoString(addDays(date.toString(), 7));
        const isPrevDaySelected = selectedDays.includes(prevDay) && isSameMonth(date, parseDate(prevDay));
        const isNextDaySelected = selectedDays.includes(nextDay) && isSameMonth(date, parseDate(nextDay));
        const isSameDayPrevWeekSelected = selectedDays.includes(sameDayPrevWeek) && isSameMonth(date, parseDate(sameDayPrevWeek)); // prettier-ignore
        const isSameDayNextWeekSelected = selectedDays.includes(sameDayNextWeek) && isSameMonth(date, parseDate(sameDayNextWeek)); // prettier-ignore

        corners.topLeft =
          (dayOfWeek === 0 && date.day < 7) ||
          (dayOfWeek === 0 && !isSameDayPrevWeekSelected) ||
          (date.day <= 7 && !isPrevDaySelected) ||
          (date.day > 7 && !isPrevDaySelected && !isSameDayPrevWeekSelected);
        corners.topRight =
          (dayOfWeek === 6 && date.day <= 7) ||
          (dayOfWeek === 6 && !isSameDayPrevWeekSelected) ||
          (!isNextDaySelected && !isSameDayPrevWeekSelected) ||
          (date.day > 7 && !isNextDaySelected && !isSameDayPrevWeekSelected);
        corners.bottomRight =
          date.day === daysInMonth ||
          (dayOfWeek === 6 && !isSameDayNextWeekSelected) ||
          (date.day > daysInMonth - 7 && !isNextDaySelected) ||
          (date.day <= daysInMonth - 7 && !isNextDaySelected && !isSameDayNextWeekSelected);
        corners.bottomLeft =
          (dayOfWeek === 0 && daysInMonth - date.day < 7) ||
          (dayOfWeek === 0 && !isSameDayNextWeekSelected) ||
          (date.day > daysInMonth - 7 && !isPrevDaySelected) ||
          (date.day <= daysInMonth - 7 && !isPrevDaySelected && !isSameDayNextWeekSelected);
      }

      if (state.highlightedRange) {
        const startDate = state.highlightedRange.start.toDate(localTimeZone);
        const endDate = state.highlightedRange.end.toDate(localTimeZone);
        const calendarDate = date.toDate(localTimeZone);
        const isStartDateSameMonth = isSameMonth(date, state.highlightedRange.start);
        const isEndDateSameMonth = isSameMonth(date, state.highlightedRange.end);

        // TODO: Update the corners rounding to take the `selectedDays` array in account
        // prettier-ignore
        corners.topLeft =
          isSelectionStart ||
          (dayOfWeek === 0 && (isStartDateSameMonth ? getDateDifferenceInDays(calendarDate, startDate) < 7 : date.day <= 7)) ||
          date.day === 1;
        // prettier-ignore
        corners.topRight =
          (isSelectionEnd && (isStartDateSameMonth ? getDateDifferenceInDays(endDate, startDate) < 7 : date.day <= 7)) ||
          (dayOfWeek === 6 && (isStartDateSameMonth ? getDateDifferenceInDays(calendarDate, startDate) < 7 : date.day <= 7));
        corners.bottomRight =
          isSelectionEnd ||
          (dayOfWeek === 6 && (getDateDifferenceInDays(endDate, calendarDate) < 7 || daysInMonth - date.day < 7)) ||
          date.day === daysInMonth;
        corners.bottomLeft =
          (isSelectionStart && getDateDifferenceInDays(endDate, startDate) < 7) ||
          (dayOfWeek === 0 && (getDateDifferenceInDays(endDate, calendarDate) < 7 || daysInMonth - date.day < 7)) ||
          (!isStartDateSameMonth && isEndDateSameMonth && date.day === 1 && state.highlightedRange.end.day <= 7);
      }
    }
    return corners;
  }, [
    date,
    isSelected,
    isSelectedByProps,
    isSelectionEnd,
    isSelectionStart,
    localTimeZone,
    locale,
    selectedDays,
    state.highlightedRange,
  ]);

  /** Class names */
  const classNames = useMemo(() => {
    const classes = ['cell'];
    isSelected && classes.push('is-selected');
    isDisabled && classes.push('is-disabled');
    isUnavailable && classes.push('is-unavailable');
    isSelectionStart && classes.push('is-selection-start');
    isSelectionEnd && classes.push('is-selection-end');
    roundedCorners.topLeft && classes.push('l-rounded-top-left');
    roundedCorners.topRight && classes.push('l-rounded-top-right');
    roundedCorners.bottomRight && classes.push('l-rounded-bottom-right');
    roundedCorners.bottomLeft && classes.push('l-rounded-bottom-left');
    return classes.join(' ');
  }, [isSelected, isDisabled, isUnavailable, isSelectionStart, isSelectionEnd, roundedCorners]);

  return (
    <td {...cellProps}>
      <div {...buttonProps} ref={cellRef} hidden={isOutsideVisibleRange} className={classNames}>
        {formattedDate}
      </div>
    </td>
  );
};

CalendarCell.propTypes = {
  ...calendarDaysProps,
  state: PropTypes.object.isRequired,
  date: PropTypes.object.isRequired,
};

export default Calendar;
