import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { set } from 'lodash';

/**
 * A Redux slice to store the user data.
 */

// eslint-disable-next-line jsdoc/valid-types
/** @typedef {import('./appSlice.js').Page} Page A page */

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    id: 0,
    name: '',
    email: '',
    token: '',
    role: '',
    pages: {
      isLoaded: false,
      /** @type {Page[]} */
      list: [],
      content: {},
    },
    opportunities: {
      isLoaded: false,
      /** @type {Page[]} */
      list: [],
      // currentPageIndex: 1,
      // perPage: 12,
      filters: {
        /** @type {string[]} */
        status: [],
        /** @type {string[]} */
        type: [],
      },
    },
  },
  reducers: {
    /**
     * Save the user.
     *
     * @param {object} state - The redux state.
     * @param {object} action - The reducer action.
     * @param {object} action.payload - The reducer data.
     * @param {number} action.payload.id - The user id.
     * @param {string} action.payload.name - The user name.
     * @param {string} action.payload.email - The user e-mail.
     * @param {string} action.payload.token - The application token.
     * @param {string} action.payload.role - The user role.
     */
    saveUser: (state, { payload: { id, name, email, token, role } }) => {
      state.id = id;
      state.name = name;
      state.email = email;
      state.token = token;
      state.role = role;
    },

    /**
     * Delete the user.
     *
     * @param {object} state - The redux state.
     */
    deleteUser: (state) => {
      state.id = 0;
      state.name = '';
      state.email = '';
      state.token = '';
      state.role = '';
      state.pages.isLoaded = false;
      state.pages.list = [];
      state.pages.content = {};
      state.opportunities.isLoaded = false;
      state.opportunities.list = [];
      state.opportunities.filters.status = [];
      state.opportunities.filters.type = [];
    },

    /**
     * Save the loading error.
     *
     * @param {object} state - The redux state.
     * @param {object} action - The reducer action.
     * @param {string} action.payload - The loading error.
     */
    saveError: (state, action) => {
      state.error = action.payload;
    },

    /**
     * Save the user pages.
     *
     * @param {object} state - The redux state.
     * @param {object} action - The reducer action.
     * @param {string} action.payload - The user pages.
     */
    savePagesList: (state, action) => {
      state.pages.isLoaded = true;
      state.pages.list = action.payload;
      state.pages.content = {};
    },

    // /**
    //  * Delete the user pages.
    //  *
    //  * @param {object} state - The redux state.
    //  */
    // deletePagesList: (state) => {
    //   state.pages.list = [];
    // },

    /**
     * Save the `pages.isLoading` state
     *
     * @param {object} state - The redux state.
     */
    saveIsPagesListLoaded: (state) => {
      state.pages.isLoaded = false;
    },

    /**
     * Save the content of a page.
     *
     * @param {object} state - The redux state.
     * @param {object} state.pages - The user pages.
     * @param {object} action - The reducer action.
     * @param {string} action.payload - The user pages.
     * @param {Page.pageName} action.payload.pageName - The page name.
     * @param {string} action.payload.content - The content.
     */
    savePageContent: ({ pages }, { payload: { pageName, content } }) => {
      pages.content[pageName] = content;
    },

    /**
     * Save a value to the content of a page.
     *
     * @param {object} state  The redux state.
     * @param {object} state.pages  The user pages.
     * @param {object} state.pages.content  The user content.
     * @param {object} action  The reducer action.
     * @param {string} action.payload  The user pages.
     * @param {Page.pageName} action.payload.pageName  The page name.
     * @param {string} action.payload.fieldPath  The field path.
     * @param {any} action.payload.value  The value to save.
     */
    savePageData: ({ pages: { content } }, { payload: { pageName, fieldPath, value } }) => {
      if (content[pageName] === undefined) {
        content[pageName] = {};
      }
      set(content[pageName], fieldPath, { value, errorMessage: '' });
    },

    /**
     * Save the opportunities list.
     *
     * @param {object} state - The redux state.
     * @param {object} action - The reducer action.
     * @param {Page[]} action.payload - The opportunities list.
     */
    saveOpportunitiesList: (state, action) => {
      state.opportunities.list = action.payload;
      state.opportunities.isLoaded = true;
      process.env.NODE_ENV === 'development' && console.info(state.opportunities.list);
    },

    /**
     * Delete an opportunity.
     *
     * @param {object} state - The redux state.
     * @param {object} action - The reducer action.
     * @param {Page[]} action.payload - The id of the opportunity to delete.
     */
    deleteOpportunity: (state, action) => {
      state.opportunities.list = state.opportunities.list.filter((opportunity) => opportunity.id !== action.payload);
    },

    // /**
    //  * Save the number of opportunities list items to display per page.
    //  *
    //  * @param {object} state  The Redux state.
    //  * @param {object} action  The reducer action.
    //  * @param {object} action.payload  The number of items to display per page.
    //  */
    // saveOpportunitiesPerPage: (state, action) => {
    //   state.opportunities.perPage = action.payload;
    //   state.currentPageIndex = 1;
    // },

    // /**
    //  * Save the current opportunities page index.
    //  *
    //  * @param {object} state  The redux state.
    //  * @param {object} action  The reducer action.
    //  * @param {number} action.payload  The current page index.
    //  */
    // saveCurrentOpportunitiesPageIndex: (state, action) => {
    //   state.opportunities.currentPageIndex = action.payload;
    // },

    // /**
    //  * Clear all the active opportunities filters, except those given in the `except` array, if provided.
    //  *
    //  * @param {object} state - The Redux state.
    //  * @param {object} action - The reducer action.
    //  * @param {object} action.payload - The reducer data.
    //  * @param {string[]} action.payload.except - The filter that must not be cleared.
    //  */
    // clearOpportunitiesFilters: (state, action) => {
    //   Object.keys(state.opportunities.filters).forEach((filterKey) => {
    //     if (!action.payload || !action.payload.except.includes(filterKey)) {
    //       state.opportunities.filters[filterKey] = filterKey !== 'search' ? [] : '';
    //     }
    //   });
    // },

    // /**
    //  * Save the given opportunities filter.
    //  *
    //  * @param {object} state - The Redux state.
    //  * @param {object} action - The reducer action.
    //  * @param {object} action.payload - The reducer data.
    //  * @param {string} action.payload.filterKey - The filter key.
    //  * @param {string[]|number[]} action.payload.values - The filter values.
    //  */
    // saveOpportunitiesFilter: (state, action) => {
    //   state.opportunities.filters[action.payload.filterKey] = action.payload.values;
    //   state.opportunities.currentPageIndex = 1;
    // },
  },
});

export const {
  saveUser,
  deleteUser,
  saveError,
  savePagesList,
  // deletePagesList,
  saveIsPagesListLoaded,
  savePageContent,
  savePageData,
  saveOpportunitiesList,
  deleteOpportunity,
  // saveOpportunitiesPerPage,
  // saveCurrentOpportunitiesPageIndex,
  // clearOpportunitiesFilters,
  // saveOpportunitiesFilter,
} = userSlice.actions;

/**
 * Return whether the user is logged in.
 *
 ** This does not dispense with using the useAuth hook before exposing critical data.
 *
 * @returns {boolean} - Whether the user is logged in.
 */
export const isLogged = createSelector(
  (state) => state.user,
  (user) => user.id !== 0 && user.name !== '' && user.email !== '' && user.token !== '' && user.role !== ''
);

/**
 * Return whether the user has publish rights.
 *
 * @returns {boolean} - Whether the user has publish rights.
 */
export const selectHasPublishRights = createSelector(
  (state) => state.user,
  (user) => ['administrator', 'editor', 'author'].includes(user.role)
);

/**
 * Return a page in a given list, by its name and a given language.
 *
 * @param {Page[]} list - The pages list.
 * @param {string} pageName - The page name.
 * @param {string} language - The page language.
 * @returns {Page} - The desired page.
 */
export const getPage = (list, pageName, language) =>
  list.filter((page) => page.pageName === pageName && page.lang === language)[0];

/**
 * Return a page by its name and a given language-
 *
 * @param {object} state - The React state.
 * @param {string} pageName - The page name.
 * @param {string} language - The page language.
 * @returns {Page} - The desired page.
 */
export const selectPage = createSelector(
  (state) => state.user.pages,
  (_, pageName) => pageName,
  (_, __, language) => language,
  (pages, pageName, language) => getPage(pages.list, pageName, language)
);

/**
 * Return the content of a page.
 *
 * @param {object} state - The React state.
 * @param {string} pageName - The page name.
 * @returns {Page} - The desired page.
 */
export const selectPageContent = createSelector(
  (state) => state.user.pages,
  (_, pageName) => pageName,
  (pages, pageName) => pages.content[pageName]
);

/**
 * Return the pages list filtered by a given language.
 *
 * @param {string} language - The desired language.
 * @returns {Page[]}
 */
export const selectPagesList = createSelector(
  (state) => state.user.pages,
  (_, language) => language,
  (pages, language) => pages.list.filter((page) => page.lang === language)
);

/**
 * Return the opportunities list filtered by a given language.
 *
 * @returns {object}
 */
export const selectOpportunitiesList = createSelector(
  (state) => state.user.opportunities,
  (opportunities) => opportunities.list
);

// /**
//  * Return a slice the opportunities list filtered by a given language.
//  *
//  * @param {number} start - The slice start index.
//  * @param {number} length - The slice length.
//  * @returns {object}
//  */
// export const selectOpportunitiesSlice = createSelector(
//   (state) => state.user.opportunities,
//   (_, start) => start,
//   (_, __, length) => length,
//   (opportunities, start, length) =>
//     opportunities.list.slice(
//       start || (opportunities.currentPageIndex - 1) * opportunities.perPage,
//       (start || (opportunities.currentPageIndex - 1) * opportunities.perPage) + (length || opportunities.perPage)
//     )
// );

/**
 * Return the opportunities list, after the Redux filters have been applied.
 *
 * @returns {object} - The opportunities list.
 */
export const selectFilteredOpportunitiesList = createSelector(
  (state) => state.user.opportunities,
  ({ list, filters }) =>
    list.filter((opportunity) =>
      Object.entries(filters).every(([key, values]) => values.length === 0 || values.includes(opportunity[key]))
    )
);

// /**
//  * Return a slice the opportunities list, after the Redux filters have been applied.
//  *
//  * @param {number} start - The start index.
//  * @param {number} length - The slice length.
//  * @returns {object} - The opportunities list slice.
//  */
// export const selectFilteredOpportunitiesSlice = createSelector(
//   (state) => state.user.events,
//   (_, start) => start,
//   (_, __, length) => length,
//   ({ list, filters, currentPageIndex, perPage }, start, length) =>
//     list
//       .filter((opportunity) =>
//         Object.entries(filters).every(([key, values]) => values.length === 0 || values.includes(opportunity[key]))
//       )
//       .slice(
//         start || (currentPageIndex - 1) * perPage,
//         (start || (currentPageIndex - 1) * perPage) + (length || perPage)
//       )
// );

/**
 * Return whether any opportunities filters are currently applied.
 *
 * @returns {boolean} - Whether any filters are active.
 */
export const selectHasFiltersApplied = createSelector(
  (state) => state.user.opportunities.filters,
  (filters) => Object.values(filters).some((filterValues) => filterValues.length > 0)
);

export default userSlice.reducer;
