import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

/** Redux */
import { selectPage } from '../../redux/pagesSlice';

/** Hooks */
import { useMeta } from '../../hooks/useMeta';
import { useNavigateWithLoader } from '../../hooks/useNavigateWithLoader';

/** Layouts */
import { Template, TemplateHeader, TemplateContent } from '../layouts/Template';

/**
 * Page404 template.
 *
 * @component
 * @param {object} props - The properties object.
 * @returns {JSX.Element} - The rendered component.
 */
const Page404 = (props) => {
  const { t: __, i18n } = useTranslation();
  const setMeta = useMeta();
  const { removeLoader } = useNavigateWithLoader();

  const frontPage = useSelector((state) => selectPage(state, 'frontpage', i18n.language));
  const page = useMemo(
    () => ({
      url: '',
      restUrl: '',
      title: __('template.404.Page not found'),
      templateName: 'Page404',
      pageName: 'page-404',
      lang: i18n.language,
      translations: frontPage.translations,
      data: [],
      type: '404',
    }),
    [__, i18n.language, frontPage]
  );

  /** Set the meta data of the page */
  setMeta({ ...page, description: __('template.404.The page you are looking for does not exist') });

  /** Remove the loader */
  useEffect(() => {
    removeLoader();
  }, [removeLoader]);

  return (
    <Template page={page}>
      <TemplateHeader color="sage" data={{ title: __('template.404.Page not found') }} />
      <TemplateContent hasFrontPageLink>
        <p>{__('template.404.The page you are looking for does not exist')}.</p>
      </TemplateContent>
    </Template>
  );
};

export default Page404;
