import { PropTypes } from 'prop-types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

/** Components */
import Form from './Form';

/**
 * VerifyEmailForm Component.
 *
 * A form component for user login and logout functionality.
 * Displays login fields if the user is not logged in and a logout button otherwise.
 * Integrates with Redux for state management and handles cookies for session management.
 *
 * @component
 * @param {object} props - The properties object.
 * @param {Function} [props.onVerify] - Callback function executed after logout.
 * @returns {JSX.Element} The rendered VerifyEmailForm component.
 */
const VerifyEmailForm = ({ onVerify = () => {}, ...props }) => {
  const { t: __ } = useTranslation();

  const fields = useMemo(
    () => ({
      email: { type: 'email', label: __('form.E-mail'), minLength: 6, maxLength: 128, isRequired: true },
    }),
    [__]
  );

  return (
    <Form
      type="verify-email"
      action="user/verify-email"
      fields={fields}
      label={__('form.Registration form')}
      buttonLabel={__('button.Register')}
      onVerify={onVerify}
    />
  );
};

VerifyEmailForm.propTypes = {
  onVerify: PropTypes.func,
};

export default VerifyEmailForm;
