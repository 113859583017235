import PropTypes from 'prop-types';
import { useRef, useMemo, useContext, createContext } from 'react';
import { useFocusRing, mergeProps } from 'react-aria';
import { useRadioGroupState } from 'react-stately';
import { useRadio, useRadioGroup } from 'react-aria';

const RadioContext = createContext();

/**
 * RadioGroup component
 *
 * A React component that renders a group of radio buttons, supporting features like labels,
 * descriptions, and error messages.
 *
 * @component
 * @param {object} props - The properties object.
 * @param {React.ReactNode|React.ReactNode[]} props.children - The radio buttons to be rendered within the group.
 * @param {string} [props.label] - The label for the radio group.
 * @param {string} [props.ariaLabelledby] - ID of element that labels this group.
 * @param {string} [props.description] - A description of the radio group.
 * @param {string} [props.errorMessage] - An error message displayed when the group is in an invalid state.
 * @param {boolean} [props.isRequired] - Whether one value of the radio group must be selected.
 * @returns {JSX.Element} The rendered component.
 */
export const RadioGroup = (props) => {
  const { children, label, 'aria-labelledby': ariaLabelledby, description, errorMessage, isRequired } = props;
  const state = useRadioGroupState(props);
  const { radioGroupProps, labelProps, descriptionProps, errorMessageProps } = useRadioGroup(props, state);
  ariaLabelledby && (radioGroupProps['aria-labelledby'] = ariaLabelledby);

  return (
    <div {...radioGroupProps} className={`field ${isRequired ? 'is-required' : ''}`}>
      {label && <span {...labelProps}>{label}</span>}
      <RadioContext.Provider value={state}>{children}</RadioContext.Provider>
      {description && (
        <div {...descriptionProps} className="field-description">
          {description}
        </div>
      )}
      {errorMessage && state.isInvalid && (
        <div {...errorMessageProps} className="field-error">
          {errorMessage}
        </div>
      )}
    </div>
  );
};

RadioGroup.propTypes = {
  // className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  label: PropTypes.string,
  'aria-labelledby': PropTypes.string,
  description: PropTypes.string,
  errorMessage: PropTypes.string,
  isRequired: PropTypes.bool,
};

/**
 * Radio component.
 *
 * @component
 * @param {object} props - The properties object.
 * @param {string} [props.className] - The class names to add to the element.
 * @param {string} [props.value] - The value of the radio.
 * @param {boolean} [props.isDisabled=false] - Whether the radio is disabled.
 * @param {boolean} [props.isIndeterminate=false] - Whether the radio is in an indeterminate state.
 * @param {React.ReactNode|React.ReactNode[]} props.children - The label or content for the radio.
 * @returns {JSX.Element} The rendered Radio component.
 */
export const Radio = ({ className, ...props }) => {
  const { children } = props;

  const inputRef = useRef();

  const state = useContext(RadioContext);
  const { inputProps } = useRadio(props, state, inputRef);
  const { isFocusVisible, focusProps } = useFocusRing();
  const isSelected = state.selectedValue === props.value;
  const isDisabled = state.isDisabled || props.isDisabled;

  /** Remove tabIndex=-1 from inputProps and handle it manually. */
  const { tabIndex: _, ...restInputProps } = inputProps;

  /** Class names */
  const classNames = useMemo(() => {
    const classes = ['radio'];
    isSelected && classes.push('is-selected');
    isFocusVisible && classes.push('is-focused');
    className && classes.push(className);
    return classes.join(' ');
  }, [isSelected, isFocusVisible, className]);

  return (
    <label className={`radio-label ${isDisabled ? 'is-disabled' : ''}`}>
      <span className="visually-hidden">
        <input ref={inputRef} {...mergeProps(restInputProps, focusProps)} tabIndex={0} />
      </span>
      <span className={classNames} aria-hidden="true" />
      <span>{children}</span>
    </label>
  );
};

Radio.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  isDisabled: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default RadioGroup;
