import { isValidEmail, isValidHttpUrl, isValidPassword, stringToFloat } from './String';
import { hasProperty } from './Object';
import { isFloat } from './Math';

/**
 * Validates a field value based on its type and constraints.
 *
 * @param {string} fieldType - The type of the field.
 * @param {*} value - The value to validate.
 * @param {object} field - The field configuration object.
 * @param {object} content - The form content (needed for password matching).
 * @param {Function} __ - Translation function.
 * @returns {string} Error message if validation fails, empty string if valid.
 */
export const validateFieldByType = (fieldType, value, field, content, __) => {
  let errorMessage = '';

  switch (fieldType) {
    case 'text':
    case 'textarea':
    case 'tel':
      if (value) {
        if (field.minLength && value.length < field.minLength) {
          errorMessage = __('form.The minimum length is (n) characters').replace('(n)', field.minLength);
        } else if (field.maxLength && value.length > field.maxLength) {
          errorMessage = __('form.The maximum length is (n) characters').replace('(n)', field.maxLength);
        }
      }
      break;

    case 'email':
      if (value && !isValidEmail(value)) {
        errorMessage = __('form.The e-mail address is invalid');
      }
      break;

    case 'url':
      if (value && !isValidHttpUrl(value)) {
        errorMessage = __('form.The website address is not valid');
      }
      break;

    case 'float':
      if (value && !isFloat(stringToFloat(value))) {
        errorMessage = __('form.The number is not valid');
      }
      break;

    case 'number':
    case 'slider':
      if (value && !Number.isInteger(parseInt(value, 10))) {
        errorMessage = __('form.The number is not valid');
      }
      break;

    case 'password':
      if (value) {
        if (field.shouldMatch && value !== content[field.shouldMatch]?.value) {
          errorMessage = __('form.Password confirmation does not match');
        } else if (!isValidPassword(value)) {
          errorMessage = __('form.Password must be at least 12 characters long...');
        }
      }
      break;

    case 'image':
      if (hasProperty(value, 'name') || hasProperty(value, 'blob') || hasProperty(value, 'type')) {
        if (
          !hasProperty(value, 'name') ||
          !value.name.match(/\.(jpg|jpeg|png|gif|webp|svg)$/i) ||
          !hasProperty(value, 'type') ||
          !value.type.startsWith('image/')
        ) {
          errorMessage = __('form.The image format is not supported');
        } else if (!hasProperty(value, 'blob') || !value.blob.startsWith('blob:')) {
          errorMessage = __('form.The image is not valid');
        } else if (field.maxSize && value.size > field.maxSize * 1024 * 1024) {
          errorMessage = __('form.The image size must not exceed (n) MB').replace('(n)', field.maxSize);
        }
      }
      break;

    case 'calendar':
      if (value?.startDate && value?.endDate) {
        const startDate = new Date(value.startDate);
        const endDate = new Date(value.endDate);

        if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
          errorMessage = __('form.The dates are invalid');
        } else if (startDate > endDate) {
          errorMessage = __('form.The end date cannot be before the start date');
        }
      }
      break;

    case 'checkboxGroup':
      if (value && Array.isArray(value)) {
        if (field.minLength && value.length < field.minLength) {
          errorMessage = __('form.You must select at least (n) items').replace('(n)', field.minLength);
        } else if (field.maxLength && value.length > field.maxLength) {
          errorMessage = __('form.You must select a maximum of (n) items').replace('(n)', field.maxLength);
        }
      }
      break;

    case 'group':
    case 'radioGroup':
    case 'checkbox':
    case 'switch':
    case 'hidden':
      break;

    default:
      errorMessage = __('form.Data type not supported');
      break;
  }

  return errorMessage;
};
