import PropTypes from 'prop-types';
import { useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

/** PropTypes */
import { colorPropTypes } from '../../propTypes';

/** Redux */
import { isLogged, selectPagesList as selectUserPages, getPage as getUserPage } from '../../redux/userSlice';
import { selectPage } from '../../redux/pagesSlice';

/** Hooks */
import { useMenu } from '../../hooks/useMenu';
import { useNavigateWithLoader } from '../../hooks/useNavigateWithLoader';
import { useAuth } from '../../hooks/useAuth';

/** Components */
import Button from '../Button';
import Menu from '../Menu';
import Link from '../Link';
import LoginForm from '../forms/LoginForm';

/**
 * LoginMenu component that handles user authentication and navigation.
 * Displays a login button for unauthenticated users and a dropdown menu for authenticated users,
 * allowing navigation to user-specific pages or access to the login form.
 *
 * @component
 * @param {object} props - The properties object.
 * @param {string} [props.buttonColor] - The color for the menu trigger button.
 * @param {boolean} [props.redirect=true] - Whether to redirect the user on login.
 * @param {boolean} [props.shouldOpenOnMount=false] - Whether the menu should be opened on mount.
 * @returns {JSX.Element} The rendered LoginMenu component.
 */
const LoginMenu = ({ buttonColor = 'cream', redirect = true, shouldOpenOnMount = false, ...props }) => {
  useAuth();
  const { navigate } = useNavigateWithLoader();
  const { t: __, i18n } = useTranslation();

  const frontPage = useSelector((state) => selectPage(state, 'frontpage', i18n.language));
  const userPagesList = useSelector((state) => selectUserPages(state, i18n.language));

  /** Menu refs, state and props */
  const { menuTriggerRef, menuTriggerProps, menuRef, menuState, menuProps } = useMenu(props);

  /** Open menu on mount if shouldOpenOnMount is true. */
  const hasHandledMount = useRef(false);
  useEffect(() => {
    if (shouldOpenOnMount && !hasHandledMount.current) {
      menuState.open();
      hasHandledMount.current = true;
      /** Remove the hash from the URL without causing a page reload. */
      window.history.replaceState(null, '', window.location.pathname);
    }
  }, [shouldOpenOnMount, menuState]);

  /** User */
  const isUserLogged = useSelector(isLogged);
  const user = useSelector((state) => state.user);

  return (
    <>
      <Button
        ref={menuTriggerRef}
        {...menuTriggerProps}
        layout="plain"
        color={buttonColor}
        className="login-menu-button"
      >
        {isUserLogged ? user.name : __('button.Login')}
      </Button>
      {menuState.isOpen && (
        <Menu ref={menuRef} {...menuProps} layout="dropdown" position="over" className="login-menu">
          {isUserLogged && <h3>{user.name}</h3>}
          {isUserLogged && (
            <ul>
              {userPagesList.map((page, index) => (
                <li key={index}>
                  <Link
                    url={page.url}
                    title={page.title}
                    onPress={() => {
                      menuState.close();
                      navigate(page.url);
                    }}
                  />
                </li>
              ))}
            </ul>
          )}
          <LoginForm
            onLogin={({ user, pages }) => {
              const pageMyOpportunities = getUserPage(pages, 'my-opportunities', i18n.language);
              if (pageMyOpportunities && redirect) {
                navigate(pageMyOpportunities.url);
              }
            }}
            onLogout={(data) => {
              menuState.close();
              navigate(frontPage.url);
            }}
          />
        </Menu>
      )}
    </>
  );
};

LoginMenu.propTypes = {
  buttonColor: colorPropTypes,
  redirect: PropTypes.bool,
  shouldOpenOnMount: PropTypes.bool,
};

export default LoginMenu;
