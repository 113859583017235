import PropTypes from 'prop-types';
import { useMemo, forwardRef, memo } from 'react';
import { useButton, mergeProps } from 'react-aria';

/** PropTypes */
import { buttonColorPropTypes } from '../propTypes';

/** Components */
import Icon from './Icon';

/**
 * Button component.
 *
 * `children` and `icon` props can't be set together.
 * Don't forget to provide an `ariaLabel` prop if you provide the `icon` prop.
 *
 * @component
 * @param {object} props - The properties object.
 * @param {string} [props.layout] - The layout of the element. @see Button.propTypes
 * @param {string} [props.color] - The color of the element. @see Button.propTypes
 * @param {string} [props.className] - The class names to add to the element.
 * @param {string} [props.icon] - The icon to set as the element's child. @see Icon component.
 * @param {string} [props.iconLayout] - The layout of the icon. @see Icon component.
 * @param {JSX.Element} [props.children] - The children of the element.
 * @param {boolean} [props.isDisabled] - Whether the element must be disabled.
 * @param {string} [props.ariaLabel] - The ARIA label to set to the element when using an icon as its child.
 * @param {boolean} [props.ariaHidden] - Whether to hide the element for ARIA.
 * @param {string} [props.role] - An aria role.
 * @param {Function} [props.onPress] - A callback for the onPress event.
 * @param {Function} [props.onMouseEnter] - A callback for the onMouseEnter event.
 * @param {Function} [props.onMouseLeave] - A callback for the onMouseLeave event.
 * @param {object} [props.style] - Additional css styles.
 * @returns {JSX.Element} - The rendered component.
 */
const Button = forwardRef(({ layout, color, className, icon, iconLayout, children, ...props }, buttonRef) => {
  const { isDisabled, ariaLabel, ariaHidden, role, onMouseEnter, onMouseLeave, style } = props;

  /** Props */
  const { buttonProps } = useButton(props, buttonRef);
  const ariaProps = { 'aria-label': ariaLabel, 'aria-hidden': ariaHidden, role };
  const mouseProps = { onMouseEnter, onMouseLeave };
  const styleProps = { style };

  /** Class names */
  const classNames = useMemo(() => {
    const classes = ['button'];
    layout && classes.push(`l-${layout}`);
    color && classes.push(`c-${color}`);
    className && classes.push(className);
    isDisabled && classes.push('is-disabled');
    return classes.join(' ');
  }, [layout, color, className, isDisabled]);

  return (
    <button ref={buttonRef} {...mergeProps(buttonProps, ariaProps, mouseProps, styleProps)} className={classNames}>
      {children ? children : icon && <Icon icon={icon} layout={iconLayout} />}
    </button>
  );
});

Button.displayName = 'Button';
Button.propTypes = {
  layout: PropTypes.oneOf(['plain', 'outline']),
  color: buttonColorPropTypes,
  className: PropTypes.string,
  icon: PropTypes.string,
  iconLayout: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  isDisabled: PropTypes.bool,
  ariaLabel: PropTypes.string,
  ariaHidden: PropTypes.bool,
  role: PropTypes.string,
  onPress: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  style: PropTypes.object,
};

export default memo(Button);
