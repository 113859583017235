import PropTypes from 'prop-types';
import { useRef, useEffect, useImperativeHandle, useCallback, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useOverlay, FocusScope, DismissButton } from 'react-aria';

/** Hooks */
import { useCloseWithTransition } from '../hooks/useCloseWithTransition';
import { useOnScroll } from '../hooks/useOnScroll';

/** Components */
import Button from './Button';

/**
 * Menu component.
 *
 * @component
 * @param {object} props - The properties object.
 * @param {string} [props.id] - The id of the menu.
 * @param {string} [props['aria-labelledby']] - The aria-labelledby prop of the menu.
 * @param {string} [props.layout] - Layout of the menu. @see Menu.propTypes
 * @param {string} [props.position] - Position of the menu. @see Menu.propTypes
 * @param {string} [props.className] - Additional class names.
 * @param {JSX.Element|JSX.Element[]} props.children - The children of the element.
 * @param {boolean} [props.shouldCloseOnBlur] - Whether to close the menu on blur.
 * @param {Function} props.onClose - Callback when menu is closed.
 * @param {Function} [props.onBeforeClose] - Callback before menu is closed.
 * @returns {JSX.Element} - The rendered component.
 */
const Menu = forwardRef(({ layout, position, className, children, shouldCloseOnBlur = false, ...props }, menuRef) => {
  const { t } = useTranslation();

  const overlayRef = useRef();
  const closeWithTransition = useCloseWithTransition(overlayRef, props.onClose, 250);
  useOnScroll(shouldCloseOnBlur ? closeWithTransition : () => {});

  /** Close handler */
  const onClose = useCallback(() => {
    props.onBeforeClose && props.onBeforeClose();
    closeWithTransition();
  }, [closeWithTransition, props]);

  /** Props */
  const { overlayProps } = useOverlay(
    {
      ...props,
      onClose,
      shouldCloseOnBlur,
      isOpen: true,
      isDismissable: true,
    },
    overlayRef
  );

  const ariaProps = {
    ...(props.id && { id: props.id }),
    ...(props['aria-labelledby'] && { 'aria-labelledby': props['aria-labelledby'] }),
  };

  /** Expose a close method to the referrer */
  useImperativeHandle(menuRef, () => ({ close: () => closeWithTransition() }), [closeWithTransition]);

  /** Close the menu when pressing the Escape key */
  useEffect(() => {
    const keyPressHandler = (e) => e.key === 'Escape' && closeWithTransition();
    window.addEventListener('keyup', keyPressHandler);
    return () => window.removeEventListener('keyup', keyPressHandler);
  }, [closeWithTransition]);

  /** Class names */
  const classNames = ['menu'];
  layout && classNames.push(`l-${layout}`);
  position && classNames.push(`p-${position}`);
  className && classNames.push(className);

  return (
    <FocusScope restoreFocus>
      <div ref={overlayRef} {...overlayProps} {...ariaProps} className={classNames.join(' ')}>
        <Button className="close-button" icon="xmark" onPress={onClose} ariaLabel={t('button.Close')} />
        {children}
        <DismissButton onDismiss={props.onClose} />
      </div>
    </FocusScope>
  );
});

Menu.displayName = 'Menu';

Menu.propTypes = {
  id: PropTypes.string,
  'aria-labelledby': PropTypes.string,
  layout: PropTypes.oneOf(['dropdown']),
  position: PropTypes.oneOf(['left', 'right', 'top', 'bottom', 'top-left', 'bottom-right', 'over']),
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  shouldCloseOnBlur: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onBeforeClose: PropTypes.func,
};

export default Menu;
