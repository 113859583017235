import PropTypes from 'prop-types';
import { memo } from 'react';
import { I18nProvider } from 'react-aria';
import { useTranslation } from 'react-i18next';
import { kebabCase } from 'lodash';

/** Components */
import {
  SwitchField,
  CheckboxField,
  NumberField,
  SliderField,
  TextField,
  TextareaField,
  ImageField,
  RangeCalendarField,
} from './Field';
import { RadioGroup, Radio } from './RadioGroup';
import { CheckboxGroup } from './CheckboxGroup';

/**
 * @typedef {object} FieldValue
 * @property {string|number|boolean|Array<string>|{startDate: string, endDate: string}} value - The field's value
 */

/**
 * @typedef {object} BaseField
 * @property {string} type - The type of the field
 * @property {string|JSX.Element|JSX.Element[]} label - The label for the field
 * @property {boolean} [isRequired=false] - Whether the field is required
 * @property {boolean} [isDisabled=false] - Whether the field is disabled
 * @property {boolean} [displayCondition] - Condition determining if field should be displayed
 * @property {string} [description] - A description of the field
 */

/**
 * @typedef {object} InputField
 * @augments BaseField
 * @property {('text'|'number'|'email'|'password'|'tel'|'url')} type - Input field type
 * @property {string} [placeholder] - Placeholder text
 */

/**
 * @typedef {object} NumberField
 * @augments BaseField
 * @property {'number'} type - Must be 'number'
 * @property {number} [minValue] - The minimum allowed value
 * @property {number} [maxValue] - The maximum allowed value
 * @property {number} [step] - The step increment
 */

/**
 * @typedef {object} SliderField
 * @augments BaseField
 * @property {'slider'} type - Must be 'slider'
 * @property {number} minValue - The minimum value
 * @property {number} maxValue - The maximum value
 * @property {number} [step] - The step increment
 */

/**
 * @typedef {object} RadioOption
 * @property {string} value - The value of the radio option
 * @property {string|JSX.Element} label - The label for the radio option
 * @property {Object<string, BaseField>} [fields] - Nested fields for this option
 */

/**
 * @typedef {object} RadioGroupField
 * @augments BaseField
 * @property {'radioGroup'} type - Must be 'radioGroup'
 * @property {RadioOption[]} radios - Available radio options
 */

/**
 * @typedef {object} CheckboxOption
 * @property {string} label - The label for the checkbox
 * @property {string} value - The value of the checkbox
 */

/**
 * @typedef {object} CheckboxGroupField
 * @augments BaseField
 * @property {'checkboxGroup'} type - Must be 'checkboxGroup'
 * @property {Object<string, CheckboxOption>} checkboxes - Available checkbox options
 * @property {number} [minLength] - Minimum number of selections required
 * @property {number} [maxLength] - Maximum number of selections allowed
 */

/**
 * @typedef {object} FieldGroup
 * @augments BaseField
 * @property {'group'} type - Must be 'group'
 * @property {Object<string, BaseField>} fields - Nested fields
 */

const customFieldProps = {
  name: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  ariaLabelledby: PropTypes.string,
  radios: PropTypes.array,
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
  getValue: PropTypes.func,
  getErrorMessage: PropTypes.func.isRequired,
  saveValue: PropTypes.func,
};

/**
 * CustomField component.
 *
 * A versatile form field component that renders different types of input fields based on the provided type prop.
 *
 * @component
 * @param {object} props - The properties object.
 * @param {string} props.name - The field name/identifier.
 * @param {('group'|'radioGroup'|'checkboxGroup'|'checkbox'|'switch'|'number'|'slider'|'calendar'|'textarea'|'image'|'text')} props.type - The type of field to render.
 * @param {string|JSX.Element|JSX.Element[]} props.label - The field label.
 * @param {string} [props.ariaLabelledby] - ID of element that labels this field.
 * @param {boolean} [props.isRequired=false] - Whether the field is required.
 * @param {boolean} [props.isDisabled=false] - Whether the field is disabled.
 * @param {function(string): FieldValue} [props.getValue] - Function to get the current value for a field. -- Required for Redux forms.
 * @param {function(string): string} props.getErrorMessage - Function to get error message for a field.
 * @param {function(string, FieldValue): void} [props.saveValue] - Function to save a new value for a field. -- Required for Redux forms.
 * @param {InputField|NumberField|SliderField|RadioGroupField|CheckboxGroupField|FieldGroup} props.field - Field-specific properties.
 * @returns {JSX.Element|null} The rendered field component or null if displayCondition is false.
 */
export const CustomField = memo(
  ({
    name,
    type,
    label,
    ariaLabelledby,
    isRequired,
    isDisabled,
    getValue = () => {},
    getErrorMessage,
    saveValue = () => {},
    ...field
  }) => {
    const { i18n } = useTranslation();

    /**
     * Build the full path for nested fields.
     *
     * @param {string} basePath - The base path of the field.
     * @param {string} fieldName - The field name.
     * @returns {string} - The nested path.
     */
    const buildFieldPath = (basePath, fieldName) => {
      if (basePath.includes('.value')) {
        return `${basePath}.${fieldName}`;
      }
      return `${basePath}.value.${fieldName}`;
    };

    return (
      field.displayCondition !== false && (
        <I18nProvider locale={i18n.language}>
          {type === 'group' ? (
            Object.entries(field.fields).map(
              ([childName, childField], index) =>
                childField.displayCondition !== false && (
                  <div
                    key={index}
                    className={`field ${childField.isRequired ? 'is-required' : ''} field-${kebabCase(childName)}`}
                  >
                    <CustomField
                      {...childField}
                      name={buildFieldPath(name, childName)}
                      isDisabled={isDisabled}
                      getValue={getValue}
                      getErrorMessage={getErrorMessage}
                      saveValue={saveValue}
                    />
                  </div>
                )
            )
          ) : type === 'radioGroup' ? (
            <CustomRadioGroup
              name={name}
              label={label}
              ariaLabelledby={ariaLabelledby}
              isRequired={isRequired}
              isDisabled={isDisabled}
              radios={field.radios}
              getValue={getValue}
              getErrorMessage={getErrorMessage}
              saveValue={saveValue}
            />
          ) : type === 'checkboxGroup' ? (
            <CheckboxGroup
              aria-label={label}
              groupTag="ul"
              checkboxTag="li"
              checkboxes={Object.entries(field.checkboxes).map(([value, { label }]) => ({ label, value }))}
              minLength={field.minLength}
              maxLength={field.maxLength}
              isRequired={isRequired}
              isDisabled={isDisabled}
              value={getValue(name) || []}
              errorMessage={getErrorMessage(name)}
              onChange={(value) => saveValue(name, value)}
            />
          ) : type === 'checkbox' ? (
            <CheckboxField
              className={kebabCase(name)}
              name={name}
              value="true"
              isDisabled={isDisabled}
              isRequired={isRequired}
              isSelected={getValue(name) === true}
              errorMessage={getErrorMessage(name)}
              onChange={(value) => saveValue(name, value)}
            >
              {label}
            </CheckboxField>
          ) : type === 'switch' ? (
            <SwitchField
              {...field}
              name={name}
              isDisabled={isDisabled}
              isRequired={isRequired}
              isSelected={getValue(name) === true}
              errorMessage={getErrorMessage(name)}
              onChange={(value) => saveValue(name, value)}
            >
              {label}
            </SwitchField>
          ) : type === 'number' ? (
            <NumberField
              {...field}
              name={name}
              label={label}
              isRequired={isRequired}
              isDisabled={isDisabled}
              value={getValue(name)}
              errorMessage={getErrorMessage(name)}
              onChange={(value) => saveValue(name, value)}
            />
          ) : type === 'slider' ? (
            <SliderField
              {...field}
              name={name}
              label={label}
              isRequired={isRequired}
              defaultValue={getValue(name) !== '' ? getValue(name) : 0}
              errorMessage={getErrorMessage(name)}
              onChange={(value) => saveValue(name, Array.isArray(value) ? value[0] : value)}
            />
          ) : type === 'calendar' ? (
            <RangeCalendarField
              {...field}
              backgroundColor="sage"
              ariaLabelledby={ariaLabelledby}
              isRequired={isRequired}
              currentValue={getValue(name) !== '' ? getValue(name) : { startDate: '', endDate: '' }}
              errorMessage={getErrorMessage(name)}
              onChange={(value) => saveValue(name, value)}
            />
          ) : type === 'textarea' ? (
            <TextareaField
              {...field}
              type={type}
              name={name}
              label={label}
              isRequired={isRequired}
              isDisabled={isDisabled}
              value={getValue(name)}
              errorMessage={getErrorMessage(name)}
              onChange={(value) => saveValue(name, value)}
            />
          ) : type === 'image' ? (
            <ImageField
              {...field}
              name={name}
              label={label}
              isRequired={isRequired}
              isDisabled={isDisabled}
              value={getValue(name)}
              errorMessage={getErrorMessage(name)}
              onChange={(value) => saveValue(name, value)}
            />
          ) : type === 'hidden' ? (
            <input type="hidden" name={name} value={getValue(name)} />
          ) : (
            <TextField
              {...field}
              type={type}
              name={name}
              label={label}
              isRequired={isRequired}
              isDisabled={isDisabled}
              value={getValue(name)}
              errorMessage={getErrorMessage(name)}
              onChange={(value) => saveValue(name, value)}
            />
          )}
        </I18nProvider>
      )
    );
  }
);

CustomField.displayName = 'CustomField';
CustomField.propTypes = {
  ...customFieldProps,
  type: PropTypes.string.isRequired,
  description: PropTypes.string,
};

/**
 * CustomRadioGroup component
 *
 * A recursive radio group component that supports nested fields.
 *
 * @component
 * @param {object} props - The properties object.
 * @param {string} props.name - The name of the radio group field.
 * @param {string} [props.label] - The label text for the radio group.
 * @param {string} [props.ariaLabelledby] - ID of element that labels this group.
 * @param {boolean} [props.isRequired=false] - Whether the field is required.
 * @param {boolean} [props.isDisabled=false] - Whether the field is disabled.
 * @param {RadioOption[]} props.radios - Array of radio options.
 * @param {function(string): FieldValue} props.getValue - Function to get the current value.
 * @param {function(string): string} props.getErrorMessage - Function to get error message.
 * @param {function(string, FieldValue): void} props.saveValue - Function to save a new value.
 * @returns {JSX.Element} The rendered CustomRadioGroup component.
 *
 * You have to provide either:
 * - the label prop
 * - the ariaLabelledby prop
 */
export const CustomRadioGroup = memo(
  ({ name, label, ariaLabelledby, radios, isRequired, isDisabled, getValue, getErrorMessage, saveValue }) => {
    /**
     * Build the nested field path.
     *
     * @param {string} parentPath - The base path of the field.
     * @param {string} fieldName - The field name.
     * @returns {string} - The nested path.
     */
    const getNestedPath = (parentPath, fieldName) => {
      const segments = parentPath.split('.');
      const lastValueIndex = segments.lastIndexOf('value');
      if (lastValueIndex === -1) {
        return fieldName;
      }
      const basePath = segments.slice(0, lastValueIndex).join('.');
      return `${basePath}.value.${fieldName}`;
    };

    return (
      <RadioGroup
        name={name}
        label={label}
        aria-labelledby={ariaLabelledby}
        isRequired={isRequired}
        isDisabled={isDisabled}
        value={getValue(name)}
        errorMessage={getErrorMessage(name)}
        isInvalid={getErrorMessage(name).length > 0}
        onChange={(value) => {
          /** Clear previous nested fields when radio selection changes. */
          const previousRadio = radios.find((radio) => radio.value === getValue(name));
          if (previousRadio?.fields) {
            Object.keys(previousRadio.fields).forEach((fieldName) => {
              saveValue(getNestedPath(name, fieldName), '');
            });
          }

          /** Save new value. */
          saveValue(name, value);
        }}
      >
        <ul>
          {radios.map(({ value, label, fields }, index) => (
            <li key={index}>
              <Radio value={value} className={`${kebabCase(value)}-radio`}>
                {label}
              </Radio>
              {fields &&
                value === getValue(name) &&
                Object.entries(fields).map(([fieldName, field], index) => (
                  <CustomField
                    key={index}
                    {...field}
                    name={getNestedPath(name, fieldName)}
                    isDisabled={isDisabled}
                    getValue={getValue}
                    getErrorMessage={getErrorMessage}
                    saveValue={saveValue}
                  />
                ))}
            </li>
          ))}
        </ul>
      </RadioGroup>
    );
  }
);

CustomRadioGroup.displayName = 'CustomRadioGroup';
CustomRadioGroup.propTypes = customFieldProps;

export default CustomField;
