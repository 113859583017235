import { useRef, useImperativeHandle } from 'react';
import { useMenuTrigger } from 'react-aria';
import { useMenuTriggerState } from 'react-stately';

/**
 * useMenu Hook.
 *
 * Prepare refs, state and props for a menu and its trigger button, using react-aria.
 *
 * @param {object} props - The props of the menu trigger
 * @returns {object} - The refs, state and props
 * @see https://react-spectrum.adobe.com/react-stately/useMenuTriggerState.html#api
 * @see https://react-spectrum.adobe.com/react-aria/useMenuTrigger.html#api
 */
const useMenu = (props = {}) => {
  /** Menu props */
  const menuTriggerRef = useRef();
  const menuRef = useRef();
  const menuState = useMenuTriggerState({});
  const { menuTriggerProps, menuProps } = useMenuTrigger(props, menuState, menuTriggerRef);

  return {
    menuTriggerRef,
    menuTriggerProps: {
      ...menuTriggerProps,
      ...(props.isDisabled && { isDisabled: true }),
      ...(props.onFocus && { onFocus: props.onFocus }),
    },
    menuRef,
    menuState,
    menuProps: { ...menuProps, onClose: menuState.close },
  };
};

/**
 * useMenuImperativeHandle Hook.
 *
 * Exposes props to the referrer.
 *
 * @param {object} ref - A ref to the element containing the menu
 * @param {object} menuRef - A ref to the menu element
 * @param {object} menuState - The state of the menu
 * @param {object} additionalProps - Additional props to espose
 */
const useMenuImperativeHandle = (ref, menuRef, menuState, additionalProps = {}) => {
  /** Expose props to the referrer */
  useImperativeHandle(
    ref,
    /* eslint-disable jsdoc/require-returns */
    () => ({
      /** Whether the menu is open */
      isOpen: menuState.isOpen,
      /** A method to close the menu */
      close: () => menuRef.current.close(),
      ...additionalProps,
    }),
    /* eslint-enable jsdoc/require-returns */
    [additionalProps, menuRef, menuState.isOpen]
  );
};

export { useMenu, useMenuImperativeHandle };
export default useMenu;
