import PropTypes from 'prop-types';
import { useMemo, forwardRef, memo } from 'react';
import { useLink, mergeProps } from 'react-aria';

/** PropTypes */
import { colorPropTypes, linkPropTypes } from '../propTypes';

/** Hooks */
import { useNavigateWithLoader } from '../hooks/useNavigateWithLoader';

/**
 * Link component.
 *
 * @component
 * @param {object} props - The properties object.
 * @param {string} props.url - The destination of the link.
 * @param {string} [props.target] - The target of the link.
 * @param {string} [props.title] - The title of the link, to use instead of children.
 * @param {string} [props.layout] - The layout of the element. @see Link.propTypes
 * @param {string} [props.color] - The color of the element. @see Link.propTypes
 * @param {string} [props.className] - The class names to add to the element.
 * @param {JSX.Element} [props.children] - The children of the element.
 * @param {string} [props.lang] - The language of the link.
 * @param {string} [props.hrefLang] - The language of the destination of link.
 * @param {string} [props.ariaLabel] - The ARIA label to set to the element.
 * @param {string} [props.role] - An aria role.
 * @param {Function} [props.onPress] - A callback for the onPress event.
 * @param {Function} [props.onMouseEnter] - A callback for the onMouseEnter event.
 * @param {Function} [props.onMouseLeave] - A callback for the onMouseLeave event.
 * @returns {JSX.Element} - The rendered component.
 */
const Link = forwardRef(({ url, target, title, layout, color, className, children, ...props }, linkRef) => {
  const { lang, hrefLang, ariaLabel, role, onPress, onMouseEnter, onMouseLeave } = props;

  const { navigate } = useNavigateWithLoader();

  /** Remove the host from the url for local links */
  if (!process.env.REACT_APP_PUBLIC_URL.startsWith('/') && url.startsWith(process.env.REACT_APP_PUBLIC_URL)) {
    url = url.replace(process.env.REACT_APP_PUBLIC_URL, '/');
  }

  /** Props */
  const { linkProps } = useLink({
    onPress:
      onPress ||
      (() => {
        if (target === '_blank') {
          window.open(url, '_blank');
        } else if (url.indexOf('tel:') > -1 || url.indexOf('mailto:') > -1) {
          window.open(url, '_self');
        } else if (url.startsWith('#')) {
          window.location.hash = url;
        } else {
          navigate(url);
        }
      }),
  });
  const targetProps = target === '_blank' ? { rel: 'noreferrer noopener', target } : {};
  const langProps = { lang, hrefLang };
  const ariaProps = { 'aria-label': ariaLabel, role };
  const mouseProps = { onMouseEnter, onMouseLeave };

  /** Class names */
  const classNames = useMemo(() => {
    const classes = ['link'];
    ['button-plain', 'button-outline'].includes(layout) && classes.push('button');
    layout && classes.push(`l-${layout.replace('button-', '')}`);
    color && classes.push(`c-${color}`);
    className && classes.push(className);
    return classes.join(' ');
  }, [layout, color, className]);

  return (
    <a
      ref={linkRef}
      {...mergeProps(linkProps, targetProps, langProps, ariaProps, mouseProps)}
      href={url}
      className={classNames}
      onClick={(e) => e.preventDefault()}
    >
      {children || title}
    </a>
  );
});

Link.displayName = 'Link';
Link.propTypes = {
  ...linkPropTypes,
  layout: PropTypes.oneOf(['button-plain', 'button-outline']),
  color: colorPropTypes,
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  lang: PropTypes.string,
  hrefLang: PropTypes.string,
  ariaLabel: PropTypes.string,
  role: PropTypes.string,
  onPress: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
};

export default memo(Link);
