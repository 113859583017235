import PropTypes from 'prop-types';
import { useRef, forwardRef, createContext, useContext, Fragment } from 'react';
import { useCheckboxGroup, useCheckboxGroupItem, useFocusRing } from 'react-aria';
import { useCheckboxGroupState } from 'react-stately';

/** Hooks */
import { useUniqueId } from '../../../hooks/useUniqueId';

const CheckboxGroupContext = createContext(null);

/**
 * CheckboxGroup component
 *
 * A React component that renders a group of checkboxes, supporting features like maximum selectable items,
 * disabled states, descriptions, and error messages.
 *
 * @component
 * @param {object} props - The properties object.
 * @param {string} [props.groupTag='Fragment'] - The tag or component to wrap the checkbox group.
 * @param {string} [props.checkboxTag='Fragment'] - The tag or component to wrap each checkbox.
 * @param {string} [props.className=''] - Additional class names to apply to the component.
 * @param {string} props.label - The label for the checkbox group.
 * @param {string} props.description - A description of the checkbox group.
 * @param {string} props.errorMessage - An error message for the group.
 * @param {Array} props.checkboxes - An array of checkboxes, which can be strings, numbers, or objects containing `value` and `label`.
 * @param {Array<string|number>} props.value - The currently selected checkbox values.
 * @param {number} [props.maxLength] - The maximum number of selectable checkboxes.
 * @param {Array<string|number>} [props.disabled=[]] - An array of values representing disabled checkboxes.
 * @param {Function} props.onChange - Callback invoked when the checkbox selection changes.
 * @param {React.Ref} [groupRef] - Ref for the group container.
 * @returns {JSX.Element} The rendered component.
 */
const CheckboxGroup = forwardRef(
  ({ groupTag: GroupTag = Fragment, checkboxTag: CheckboxTag = Fragment, className = '', ...props }, groupRef) => {
    const { checkboxes, disabled = [], label, description, errorMessage, maxLength } = props;
    const errorMessageId = useUniqueId();

    const groupState = useCheckboxGroupState(props);
    const { groupProps, labelProps, descriptionProps, errorMessageProps } = useCheckboxGroup(props, groupState);

    /** Set the error message props if react-aria fail to do it when the error message changes */
    if (errorMessage && !errorMessageProps.id) {
      errorMessageProps.id = errorMessageId;
      groupProps['aria-describedby'] = `${groupProps['aria-describedby'] || ''} ${errorMessageId}`.trim();
    }

    return (
      <div ref={groupRef} {...groupProps} className={`field checkbox-group ${className}`}>
        {label && <span {...labelProps}>{label}</span>}
        <CheckboxGroupContext.Provider value={groupState}>
          <GroupTag>
            {checkboxes.map((value, index) => {
              const label = typeof value === 'object' ? value.label : value;
              typeof value === 'object' && (value = value.value);
              return (
                <CheckboxTag key={index}>
                  <Checkbox
                    value={value}
                    isDisabled={
                      disabled.includes(value) ||
                      (maxLength && !props.value.includes(value) && props.value.length === maxLength)
                    }
                  >
                    {label}
                  </Checkbox>
                </CheckboxTag>
              );
            })}
          </GroupTag>
        </CheckboxGroupContext.Provider>
        {description && (
          <div {...descriptionProps} className="field-description">
            {description}
          </div>
        )}
        {errorMessage && (
          <div {...errorMessageProps} className="field-error">
            {errorMessage}
          </div>
        )}
      </div>
    );
  }
);

CheckboxGroup.displayName = 'CheckboxGroup';
CheckboxGroup.propTypes = {
  groupTag: PropTypes.string,
  checkboxTag: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string,
  description: PropTypes.string,
  errorMessage: PropTypes.string,
  checkboxes: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        label: PropTypes.string.isRequired,
      }),
    ])
  ).isRequired,
  value: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.arrayOf(PropTypes.number)]).isRequired,
  maxLength: PropTypes.number,
  disabled: PropTypes.array,
  onChange: PropTypes.func.isRequired,
};

/**
 * Checkbox component
 *
 * A single checkbox item within a group.
 *
 * @component
 * @param {object} props - The properties object.
 * @param {string|number} props.value - The value of the checkbox.
 * @param {boolean} [props.isDisabled=false] - Whether the checkbox is disabled.
 * @param {React.ReactNode|React.ReactNode[]} props.children - The content (label) of the checkbox.
 * @returns {JSX.Element} The rendered component.
 */
const Checkbox = (props) => {
  const { value, children } = props;

  const inputRef = useRef();
  const inputState = useContext(CheckboxGroupContext);
  const { inputProps } = useCheckboxGroupItem(props, inputState, inputRef);
  const { isFocusVisible, focusProps } = useFocusRing();

  const isSelected = inputState.isSelected(value);
  const isDisabled = inputState.isDisabled || props.isDisabled;

  return (
    <label className={`checkbox-label ${isDisabled ? 'is-disabled' : ''}`}>
      <span className="visually-hidden">
        <input ref={inputRef} {...inputProps} {...focusProps} />
      </span>
      <span
        className={`checkbox ${isSelected ? 'is-selected' : ''} ${isFocusVisible ? 'is-focused' : ''}`}
        aria-hidden="true"
      />
      {typeof children === 'string' ? <span dangerouslySetInnerHTML={{ __html: children }} /> : <span>{children}</span>}
    </label>
  );
};

Checkbox.propTypes = {
  /** The value of the checkbox */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  /** Whether the checkbox is disabled */
  isDisabled: PropTypes.bool,
  /** The label of the checkbox */
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export { CheckboxGroup };
export default CheckboxGroup;
