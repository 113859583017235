import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';

/**
 * A Redux slice for the WordPress `city` taxonomy.
 */

/**
 * @typedef {object} City - Represents an item from the cities list.
 * @property {string} name - The name of the city.
 * @property {number} zoomLevel - The zoom level of the map where the city becomes visible.
 * @property {number} lat - The latitude of the city.
 * @property {number} lng - The longitude of the city.
 */

export const citiesSlice = createSlice({
  name: 'cities',
  initialState: {
    isLoaded: false,
    /** @type {City[]} */
    list: [],
    error: '',
  },
  reducers: {
    /**
     * Save the list
     *
     * @param {object} state  The redux state
     * @param {object} action  The reducer action
     * @param {object[]} action.payload  The list
     */
    saveList: (state, action) => {
      state.list = action.payload;
      state.isLoaded = true;
      process.env.NODE_ENV === 'development' && console.info(state.list);
    },

    /**
     * Save the loading error
     *
     * @param {object} state  The redux state
     * @param {object} action  The reducer action
     * @param {string} action.payload  The loading error
     */
    saveError: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const { saveList, saveError } = citiesSlice.actions;

/**
 * Return the cities list.
 *
 * @returns {City[]} The list.
 */
export const selectList = createSelector(
  (state) => state.cities,
  (cities) => cities.list
);

export default citiesSlice.reducer;
