import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

/** Hooks */
import { useMenu } from '../hooks/useMenu';

/** Components */
import Button from './Button';
import Menu from './Menu';
import Cookie from './images/Cookie';

/**
 * CookieNotice component that displays a button that opens a menu with the content of the cookie notice.
 *
 * @component
 * @param {object} props - The properties object.
 * @param {JSX.Element|JSX.Element[]} props.children - The content of the cookie notice.
 * @returns {JSX.Element} The rendered component.
 */
const CookieNotice = ({ children, ...props }) => {
  const { t: __ } = useTranslation();

  /** Menu refs, state and props */
  const { menuTriggerRef, menuTriggerProps, menuRef, menuState, menuProps } = useMenu(props);

  return (
    <div className="cookie-notice">
      <Button ref={menuTriggerRef} {...menuTriggerProps} className="cookie-button">
        <span className="label">{__('cookie.Cookie information')}</span> <Cookie />
      </Button>
      {menuState.isOpen && (
        <Menu ref={menuRef} {...menuProps} layout="dropdown" position="top-left" shouldCloseOnBlur>
          {children}
        </Menu>
      )}
    </div>
  );
};

CookieNotice.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default CookieNotice;
