import PropTypes from 'prop-types';
import { useRef, useMemo, forwardRef } from 'react';
import {
  useField,
  useTextField,
  useNumberField,
  useSearchField,
  useCheckbox,
  useLocale,
  useFocusRing,
  mergeProps,
} from 'react-aria';
import { useNumberFieldState, useSearchFieldState, useToggleState } from 'react-stately';
import { useTranslation } from 'react-i18next';

/** PropTypes */
import { colorPropTypes, colorPropTypesNoWhite, fieldPropTypes } from '../../../propTypes';

/** Hooks */
import { useUniqueId } from '../../../hooks/useUniqueId';

/** Utils */
import { breakpointUp } from '../../../util/Breakpoint';

/** Components */
import Switch from './Switch';
import Slider from './Slider';
import { RangeCalendar } from './Calendar';
import Button from '../../Button';
import Icon from '../../Icon';
import Image from '../../Image';

/**
 * A wrapper component for form fields with support for labels, descriptions, error messages, and validation states.
 *
 * @component
 * @param {object} props - The properties object.
 * @param {string} [props.className] - Additional class names for the field wrapper. Will be appended to default 'field' class.
 * @param {React.ReactNode} props.children - The field content.
 * @param {string} [props.description] - A description for the field.
 * @param {object} [props.descriptionProps] - ARIA properties for the description element.
 * @param {string} [props.errorMessage] - An error message for the field.
 * @param {object} [props.errorMessageProps] - ARIA properties for the error message element.
 * @param {boolean} [props.isRequired] - Indicates if the field is required.
 * @param {boolean} [props.isDisabled] - Indicates if the field is disabled.
 * @returns {JSX.Element} The rendered Field component.
 */
export const Field = ({ className = '', children, ...props }) => {
  const { description, descriptionProps, errorMessage, errorMessageProps, isRequired, isDisabled } = props;

  /** Class names */
  const classNames = ['field'];
  isRequired && classNames.push('is-required');
  isDisabled && classNames.push('is-disabled');
  className && classNames.push(className);

  return (
    <div className={classNames.join(' ')}>
      <div className="field-control">{children}</div>
      {description && (
        <div {...descriptionProps} className="field-description">
          {description}
        </div>
      )}
      {errorMessage && (
        <div {...errorMessageProps} className="field-error">
          {errorMessage}
        </div>
      )}
    </div>
  );
};

Field.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  description: PropTypes.string,
  descriptionProps: PropTypes.object,
  errorMessage: PropTypes.string,
  errorMessageProps: PropTypes.object,
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

/**
 * Prop types common to all fields.
 *
 * @typedef {object} FieldPropTypes
 * @property {string} label - The label of the field.
 * @property {string} [description] - The description of the field.
 * @property {string} [errorMessage] - The error message of the field.
 * @property {boolean} [isDisabled] - Indicates if the input is disabled.
 * @property {boolean} [isReadOnly] - Indicates if the input is read-only.
 * @property {boolean} [isRequired] - Indicates if the input is required.
 * @property {'valid'|'invalid'} [validationState] - The validation state of the input.
 * @property {boolean} [autoFocus] - Indicates if the input should autofocus on render.
 */

/**
 * TextField component.
 *
 * @component
 * @param {FieldPropTypes} props - The properties object.
 * @param {'text'|'email'|'url'|'tel'|'password'} [props.type='text'] - The type of the input.
 * @returns {JSX.Element} The rendered component.
 */
export const TextField = ({ type = 'text', ...props }) => {
  const { label, description, errorMessage, isRequired, isDisabled } = props;

  const fieldRef = useRef(null);
  const { labelProps, inputProps, descriptionProps, errorMessageProps } = useTextField({ ...props, type }, fieldRef);

  return (
    <Field
      className="text-field"
      description={description}
      descriptionProps={descriptionProps}
      errorMessage={errorMessage}
      errorMessageProps={errorMessageProps}
      isRequired={isRequired}
      isDisabled={isDisabled}
    >
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control -- Content is sometimes html */}
      <label {...labelProps} dangerouslySetInnerHTML={{ __html: label }} />
      <input {...inputProps} ref={fieldRef} />
    </Field>
  );
};

TextField.propTypes = {
  ...fieldPropTypes,
  type: PropTypes.oneOf(['text', 'email', 'url', 'tel', 'password']),
};

/**
 * TextareaField component.
 *
 * @component
 * @param {FieldPropTypes} props - The properties object.
 * @returns {JSX.Element} The rendered component.
 */
export const TextareaField = (props) => {
  const { label, description, errorMessage, isRequired, isDisabled } = props;

  const fieldRef = useRef(null);
  const { labelProps, inputProps, descriptionProps, errorMessageProps } =
    useTextField({ ...props, inputElementType: 'textarea' }, fieldRef); // prettier-ignore

  return (
    <Field
      className="textarea-field"
      description={description}
      descriptionProps={descriptionProps}
      errorMessage={errorMessage}
      errorMessageProps={errorMessageProps}
      isRequired={isRequired}
      isDisabled={isDisabled}
    >
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control -- Content is sometimes html */}
      <label {...labelProps} dangerouslySetInnerHTML={{ __html: label }} />
      <div className="textarea-field-wrapper">
        <textarea {...inputProps} ref={fieldRef} />
      </div>
    </Field>
  );
};

TextareaField.propTypes = fieldPropTypes;

/**
 * NumberField component.
 *
 * @component
 * @param {FieldPropTypes} props - The properties object.
 * @returns {JSX.Element} The rendered component.
 */
export const NumberField = (props) => {
  const { label, description, errorMessage, isRequired, isDisabled } = props;
  const { t: __ } = useTranslation();

  const { locale } = useLocale();
  const state = useNumberFieldState({ ...props, locale });
  const fieldRef = useRef(null);
  const {
    labelProps,
    groupProps,
    inputProps,
    descriptionProps,
    errorMessageProps,
    incrementButtonProps,
    decrementButtonProps,
  } = useNumberField(props, state, fieldRef);

  return (
    <Field
      className="number-field"
      description={description}
      descriptionProps={descriptionProps}
      errorMessage={errorMessage}
      errorMessageProps={errorMessageProps}
      isRequired={isRequired}
      isDisabled={isDisabled}
    >
      <label {...labelProps}>{label}</label>
      <div {...groupProps}>
        <Button {...decrementButtonProps}>
          <Icon icon="minus" ariaLabel={__('form.Decrease')} />
        </Button>
        <input {...inputProps} ref={fieldRef} />
        <Button {...incrementButtonProps}>
          <Icon icon="plus" ariaLabel={__('form.Increase')} />
        </Button>
      </div>
    </Field>
  );
};

NumberField.propTypes = fieldPropTypes;

/**
 * FileField component.
 *
 * @component
 * @param {FieldPropTypes} props - The properties object.
 * @param {string} [props.accept] - The mime types accepted by the field -- @example "image/*".
 * @returns {JSX.Element} The rendered component.
 */
export const FileField = forwardRef((props, fieldRef) => {
  const { label, description, errorMessage, isRequired, isDisabled, accept } = props;

  const { labelProps, inputProps, descriptionProps, errorMessageProps } = useField(props);

  return (
    <Field
      className="file-field"
      description={description}
      descriptionProps={descriptionProps}
      errorMessage={errorMessage}
      errorMessageProps={errorMessageProps}
      isRequired={isRequired}
      isDisabled={isDisabled}
    >
      <label {...labelProps}>{label}</label>
      <input
        {...inputProps}
        ref={fieldRef}
        type="file"
        accept={accept || null}
        onChange={(e) =>
          props.onChange({
            name: e.target.files[0].name,
            type: e.target.files[0].type,
            size: e.target.files[0].size,
            blob: URL.createObjectURL(e.target.files[0]),
          })
        }
      />
    </Field>
  );
});

FileField.displayName = 'FileField';
FileField.propTypes = {
  ...fieldPropTypes,
  accept: PropTypes.string,
};

/**
 * ImageField component.
 *
 * @component
 * @param {FieldPropTypes} props - The properties object.
 * @param {string} props.buttonLabel - A label for the browse button.
 * @param {string} [props.color='sage'] - The color og the browse button.
 * @param {object|string} [props.value] - The current value of the image.
 * @param {string} props.value.name - The name of the image.
 * @param {string} props.value.blob - The blob of the image.
 * @param {Function} props.onChange (value: string|{name: string, type: string, size: number, blob: string}) => void - A callback function when the image changes.
 * @returns {JSX.Element} The rendered component.
 */
export const ImageField = ({ buttonLabel, color = 'sage', ...props }) => {
  const { description, errorMessage, isRequired, isDisabled, value } = props;
  const [descriptionId, errorMessageId] = useUniqueId(2);
  const { t: __ } = useTranslation();
  const fieldRef = useRef();

  /** Image props. */
  const imageProps = useMemo(() => {
    const hasUserImage = value && typeof value === 'object' && value.blob && value.name && value.type;
    const hasUploadedImage = value && typeof value === 'object' && value.id && value.sizes;
    return hasUserImage
      ? { alt: 'value.name', src: value.blob }
      : hasUploadedImage
      ? {
          alt: '',
          image: { resized: value.sizes },
          format: 'resized',
          sizes: `100vw, ${breakpointUp('sm')} 50vw, ${breakpointUp('lg')} 33.3333vw`,
        }
      : {};
  }, [value]);

  return (
    <Field
      className="image-field"
      description={description}
      descriptionProps={description ? { id: descriptionId } : null}
      errorMessage={errorMessage}
      errorMessageProps={errorMessage ? { id: errorMessageId } : null}
      isRequired={isRequired}
      isDisabled={isDisabled}
    >
      <Button isDisabled={isDisabled} layout="plain" color={color} onPress={() => fieldRef.current.click()}>
        {buttonLabel}
      </Button>
      <FileField ref={fieldRef} {...props} />

      {value && (
        <div className="image-field-value">
          <Image className="image-field-image" layout="rounded" {...imageProps} />
          <Button layout="plain" color="rosewood" onPress={() => props.onChange('')}>
            {__('button.Delete')}
          </Button>
        </div>
      )}
    </Field>
  );
};

ImageField.propTypes = {
  ...fieldPropTypes,
  buttonLabel: PropTypes.string.isRequired,
  color: colorPropTypesNoWhite,
  value: PropTypes.oneOfType([
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      blob: PropTypes.string.isRequired,
    }),
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      sizes: PropTypes.object.isRequired,
    }),
    PropTypes.string,
    PropTypes.bool,
  ]).isRequired,
  onChange: PropTypes.func.isRequired,
};

/**
 * SwitchField component.
 *
 * @component
 * @param {FieldPropTypes} props - The properties object.
 * @param {JSX.Element|JSX.Element} props.children - The label of the switch.
 * @returns {JSX.Element} The rendered component.
 */
export const SwitchField = (props) => {
  const { description, errorMessage, isRequired, isDisabled, children } = props;
  const [descriptionId, errorMessageId] = useUniqueId(2);

  const ariaDescribedby = [description && descriptionId, errorMessage && errorMessageId].filter(Boolean).join(' ');

  return (
    <Field
      className="switch-field"
      description={description}
      descriptionProps={description ? { id: descriptionId } : null}
      errorMessage={errorMessage}
      errorMessageProps={errorMessage ? { id: errorMessageId } : null}
      isRequired={isRequired}
      isDisabled={isDisabled}
    >
      <Switch {...props} aria-describedby={ariaDescribedby || null}>
        {children}
      </Switch>
    </Field>
  );
};

SwitchField.propTypes = {
  ...fieldPropTypes,
  label: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

/**
 * CheckboxField component.
 *
 * @component
 * @param {FieldPropTypes} props - The properties object.
 * @param {boolean} [props.isIndeterminate] - Indicates if the input is LGBTQI+.
 * @param {JSX.Element|JSX.Element} props.children - The label of the checkbox.
 * @returns {JSX.Element} The rendered Checkbox component.
 */
export const CheckboxField = ({ className, ...props }) => {
  const { isRequired, description = '', errorMessage = '', children } = props;
  const [descriptionId, errorMessageId] = useUniqueId(2);
  const inputRef = useRef();

  const state = useToggleState(props);
  const { inputProps } = useCheckbox(props, state, inputRef);
  const { isFocusVisible, focusProps } = useFocusRing();
  const isSelected = state.isSelected && !props.isIndeterminate;
  const isDisabled = state.isDisabled || props.isDisabled;

  /** Class names */
  const classNames = useMemo(() => {
    const classes = ['checkbox'];
    isSelected && classes.push('is-selected');
    isFocusVisible && classes.push('is-focused');
    className && classes.push(className);
    return classes.join(' ');
  }, [isSelected, isFocusVisible, className]);

  const ariaProps =
    description || errorMessage
      ? { 'aria-describedby': [description && descriptionId, errorMessage && errorMessageId].filter(Boolean).join(' ') }
      : {};
  const descriptionProps = description ? { id: descriptionId } : {};
  const errorMessageProps = errorMessage ? { id: errorMessageId } : {};

  return (
    <Field
      className="checkbox-field"
      description={description}
      descriptionProps={descriptionProps}
      errorMessage={errorMessage}
      errorMessageProps={errorMessageProps}
      isRequired={isRequired}
      isDisabled={isDisabled}
    >
      <label className={`checkbox-label ${isDisabled ? 'is-disabled' : ''}`}>
        <span className="visually-hidden">
          <input ref={inputRef} {...mergeProps(inputProps, focusProps, ariaProps)} />
        </span>
        <span className={classNames} aria-hidden="true" />
        <span>{children}</span>
      </label>
    </Field>
  );
};

CheckboxField.propTypes = {
  ...fieldPropTypes,
  label: PropTypes.string,
  isIndeterminate: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

/**
 * SliderField component.
 *
 * @component
 * @param {FieldPropTypes} props - The properties object.
 * @param {number} props.defaultValue - The default value.
 * @returns {JSX.Element} The rendered component.
 */
export const SliderField = (props) => {
  const { description, errorMessage, isDisabled, isRequired } = props;
  const [descriptionId, errorMessageId] = useUniqueId(2);

  const ariaDescribedby = [description && descriptionId, errorMessage && errorMessageId].filter(Boolean).join(' ');

  return (
    <Field
      className="slider-field"
      description={description}
      descriptionProps={description ? { id: descriptionId } : null}
      errorMessage={errorMessage}
      errorMessageProps={errorMessage ? { id: errorMessageId } : null}
      isRequired={isRequired}
      isDisabled={isDisabled}
    >
      <Slider {...props} aria-describedby={ariaDescribedby || null} onChangeEnd={(value) => props.onChange(value)} />
    </Field>
  );
};

SliderField.propTypes = {
  ...fieldPropTypes,
  defaultValue: PropTypes.number.isRequired,
};

/**
 * RangeCalendarField component.
 *
 * @component
 * @param {FieldPropTypes} props - The properties object.
 * @param {string} props.ariaLabelledby - The ID of the element that describes the calendar.
 * @param {object} props.currentValue - The current value.
 * @param {string} props.currentValue.startDate - The start date.
 * @param {string} props.currentValue.endDate - The end date.
 * @param {string} [props.backgroundColor] - A background color for the calendar.
 * @param {string} [props.color] - A foreground color for the calendar.
 * @returns {JSX.Element} The rendered component.
 */
export const RangeCalendarField = (props) => {
  const { description, errorMessage, isDisabled, isRequired } = props;
  const [descriptionId, errorMessageId] = useUniqueId(2);
  const { t: __ } = useTranslation();

  const ariaDescribedby = [description && descriptionId, errorMessage && errorMessageId].filter(Boolean).join(' ');

  return (
    <Field
      className="range-calendar-field"
      description={description}
      descriptionProps={description ? { id: descriptionId } : null}
      errorMessage={errorMessage}
      errorMessageProps={errorMessage ? { id: errorMessageId } : null}
      isRequired={isRequired}
      isDisabled={isDisabled}
    >
      <RangeCalendar {...props} ariaDescribedby={ariaDescribedby} label={__('form.Select a date range')} />
    </Field>
  );
};

RangeCalendarField.propTypes = {
  ...fieldPropTypes,
  label: PropTypes.string,
  ariaLabelledby: PropTypes.string.isRequired,
  currentValue: PropTypes.shape({
    startDate: PropTypes.string,
    endDate: PropTypes.string,
  }).isRequired,
  backgroundColor: colorPropTypes,
  color: colorPropTypes,
};

/**
 * SearchField component.
 *
 * @component
 * @param {FieldPropTypes} props - The properties object.
 * @param {string} props.clearButtonLabel - The label for the clear button.
 * @returns {JSX.Element} The rendered component.
 */
export const SearchField = (props) => {
  const { label, clearButtonLabel, description, errorMessage, isDisabled } = props;

  const fieldRef = useRef();
  const fieldState = useSearchFieldState(props);
  // prettier-ignore
  const { labelProps, inputProps, descriptionProps, errorMessageProps, clearButtonProps } =
    useSearchField(props, fieldState, fieldRef);

  return (
    <Field
      className="search-field"
      description={description}
      descriptionProps={descriptionProps}
      errorMessage={errorMessage}
      errorMessageProps={errorMessageProps}
      isDisabled={isDisabled}
    >
      <label {...labelProps}>{label}</label>
      <input {...inputProps} ref={fieldRef} />
      {fieldState.value !== '' && (
        <Button className="clear-button" {...clearButtonProps} icon="xmark" ariaLabel={clearButtonLabel} />
      )}
    </Field>
  );
};

SearchField.propTypes = {
  ...fieldPropTypes,
  clearButtonLabel: PropTypes.string.isRequired,
};

export default Field;
