import PropTypes from 'prop-types';
import { useRef, useMemo, useImperativeHandle, forwardRef } from 'react';
import { useDateFieldState, useTimeFieldState, useDateRangePickerState } from 'react-stately';
import { useDateField, useTimeField, useField, useDateSegment, useDateRangePicker, useLocale } from 'react-aria';
import { useTranslation } from 'react-i18next';
import { parseDate, parseTime, createCalendar } from '@internationalized/date';

/** PropTypes */
import { fieldPropTypes } from '../../../propTypes';

/** Components */
import { Field } from './Field';
import Icon from '../../Icon';

/**
 * Prop types common to all fields.
 *
 * @typedef {object} FieldPropTypes
 * @property {string} label - The label of the field.
 * @property {string} [description] - The description of the field.
 * @property {string} [errorMessage] - The error message of the field.
 * @property {boolean} [isDisabled] - Indicates if the input is disabled.
 * @property {boolean} [isReadOnly] - Indicates if the input is read-only.
 * @property {boolean} [isRequired] - Indicates if the input is required.
 * @property {('valid'|'invalid')} [validationState] - The validation state of the input.
 * @property {boolean} [autoFocus] - Indicates if the input should autofocus on render.
 * @param {Function} [onChange] - Callback when the value changes.
 */

/**
 * DateField component for rendering a single date field with accessibility and validation support.
 *
 * @component
 * @param {FieldPropTypes} props - The properties object.
 * @returns {JSX.Element} The rendered component.
 */
export const DateField = (props) => {
  const { label, description, errorMessage, isRequired, isDisabled } = props;
  const { locale } = useLocale();

  const fieldRef = useRef(null);
  const fieldState = useDateFieldState({ ...props, locale, createCalendar });
  const { labelProps, fieldProps, descriptionProps, errorMessageProps } = useDateField(props, fieldState, fieldRef);

  return (
    <Field
      className="date-field"
      description={description}
      descriptionProps={descriptionProps}
      errorMessage={errorMessage}
      errorMessageProps={errorMessageProps}
      isRequired={isRequired}
      isDisabled={isDisabled}
    >
      <span {...labelProps} className="label">
        {label}
      </span>
      <div {...fieldProps} ref={fieldRef} className="input">
        {fieldState.segments.map((segment, index) => (
          <DateSegment key={index} segment={segment} state={fieldState} />
        ))}
        {fieldState.isInvalid && <Icon icon="exclamation" />}
      </div>
    </Field>
  );
};

DateField.propTypes = fieldPropTypes;

/* eslint-disable jsdoc/check-param-names */
/**
 * DateSegment - A sub-component representing a segment of a date.
 *
 * @component
 * @param {object} props - The properties object.
 * @param {object} props.segment - The date segment to render.
 * @param {boolean} props.segment.isPlaceholder - Whether the segment is a placeholder.
 * @param {string} props.segment.text - The formatted text for the segment.
 * @param {object} props.state - The state object for the date field @see https://react-spectrum.adobe.com/react-aria/useDateField.html#api.
 * @returns {JSX.Element} The rendered component.
 */
/* eslint-enable jsdoc/check-param-names */
const DateSegment = ({ segment, state }) => {
  const segmentRef = useRef(null);
  const { segmentProps } = useDateSegment(segment, state, segmentRef);

  return (
    <div {...segmentProps} ref={segmentRef} className={`date-segment ${segment.isPlaceholder ? 'is-placeholder' : ''}`}>
      {segment.text}
    </div>
  );
};

DateSegment.propTypes = {
  segment: PropTypes.shape({
    isPlaceholder: PropTypes.bool,
    text: PropTypes.string.isRequired,
  }).isRequired,
  state: PropTypes.object.isRequired,
};

/**
 * DateRangeField - A component for rendering a range of dates with start and end fields.
 *
 * @component
 * @param {FieldPropTypes} props - The properties object.
 * @param {object} props.value - The current date range value.
 * @param {string} props.value.startDate - The start date.
 * @param {string} props.value.endDate - The end date.
 * @returns {JSX.Element} The rendered component.
 */
export const DateRangeField = forwardRef(({ value: { startDate, endDate }, ...props }, dateRangeFieldRef) => {
  const { t: __ } = useTranslation();
  const { label, description, isRequired, isDisabled } = props;

  const datePickerState = useDateRangePickerState({
    ...props,
    ...(startDate && endDate && { value: { start: parseDate(startDate), end: parseDate(endDate) } }),
    onChange: (value) => value && props.onChange({ startDate: value.start.toString(), endDate: value.end.toString() }),
  });
  const groupRef = useRef();
  const { labelProps, groupProps, startFieldProps, endFieldProps, descriptionProps, errorMessageProps } =
    useDateRangePicker(props, datePickerState, groupRef);

  const errorMessage = useMemo(
    () => (datePickerState.isInvalid ? __('form.The dates are invalid') : props.errorMessage || ''),
    [__, datePickerState.isInvalid, props.errorMessage]
  );
  /** Expose the errors the referrer */
  useImperativeHandle(dateRangeFieldRef, () => ({ errorMessage }), [errorMessage]);

  return (
    <Field
      className="date-range-field"
      description={description}
      descriptionProps={descriptionProps}
      errorMessage={errorMessage}
      errorMessageProps={errorMessageProps}
      isRequired={isRequired}
      isDisabled={isDisabled}
    >
      {label && (
        <span {...labelProps} className="label">
          {label}
        </span>
      )}
      <div className="field">
        <div {...groupProps} ref={groupRef} className="field-group">
          <DateField {...startFieldProps} label={__('form.Start date')} />
          <Icon icon="chevron-right" ariaLabel="-" />
          <DateField {...endFieldProps} label={__('form.End date')} />
        </div>
      </div>
    </Field>
  );
});

DateRangeField.displayName = 'DateRangeField';
DateRangeField.propTypes = {
  ...fieldPropTypes,
  value: PropTypes.shape({
    startDate: PropTypes.string,
    endDate: PropTypes.string,
  }),
};

/**
 * TimeField component for rendering a single time field with accessibility and validation support.
 *
 * @component
 * @param {FieldPropTypes} props - The properties object.
 * @returns {JSX.Element} The rendered component.
 */
export const TimeField = (props) => {
  const { label, description, errorMessage, isRequired, isDisabled } = props;
  const { locale } = useLocale();

  const fieldRef = useRef(null);
  const fieldState = useTimeFieldState({ ...props, locale });
  const { labelProps, fieldProps, descriptionProps, errorMessageProps } = useTimeField(props, fieldState, fieldRef);

  return (
    <Field
      className="time-field"
      description={description}
      descriptionProps={descriptionProps}
      errorMessage={errorMessage}
      errorMessageProps={errorMessageProps}
      isRequired={isRequired}
      isDisabled={isDisabled}
    >
      <span {...labelProps} className="label">
        {label}
      </span>
      <div {...fieldProps} ref={fieldRef} className="input">
        {fieldState.segments.map((segment, index) => (
          <DateSegment key={index} segment={segment} state={fieldState} />
        ))}
        {fieldState.isInvalid && <Icon icon="exclamation" />}
      </div>
    </Field>
  );
};

TimeField.propTypes = {
  ...fieldPropTypes,
};

/**
 * TimeRangeField - A component for rendering a range of times with start and end fields.
 *
 * @component
 * @param {FieldPropTypes} props - The properties object.
 * @param {object} props.value - The current time range value.
 * @param {string} props.value.startTime - The start time.
 * @param {string} props.value.endTime - The end time.
 * @returns {JSX.Element} The rendered component.
 */
export const TimeRangeField = ({ value: { startTime, endTime }, autoFocus, ...props }) => {
  const { t: __ } = useTranslation();
  const { label, description, errorMessage, isRequired, isDisabled } = props;

  const { labelProps, descriptionProps, errorMessageProps } = useField(props);

  return (
    <Field
      className="time-range-field"
      description={description}
      descriptionProps={descriptionProps}
      errorMessage={errorMessage}
      errorMessageProps={errorMessageProps}
      isRequired={isRequired}
      isDisabled={isDisabled}
    >
      {label && (
        <span {...labelProps} className="label">
          {label}
        </span>
      )}
      <div className="field">
        <div role="group" className="field-group">
          <TimeField
            value={startTime ? parseTime(startTime) : null}
            label={__('form.Start time')}
            onChange={(value) => value && props.onChange({ startTime: value.toString().slice(0, 5), endTime })}
            onKeyUp={props.onKeyUp}
            autoFocus={autoFocus}
          />
          <Icon icon="chevron-right" ariaLabel="-" />
          <TimeField
            value={endTime ? parseTime(endTime) : null}
            label={__('form.End time')}
            onChange={(value) => value && props.onChange({ startTime, endTime: value.toString().slice(0, 5) })}
            onKeyUp={props.onKeyUp}
          />
        </div>
      </div>
    </Field>
  );
};

TimeRangeField.propTypes = {
  ...fieldPropTypes,
  value: PropTypes.shape({
    startTime: PropTypes.string,
    endTime: PropTypes.string,
  }),
};

export default DateField;
