import PropTypes from 'prop-types';

/** PropTypes */
import { imagePropTypes } from '../propTypes';

/**
 * Image Component
 *
 * Renders an image with optional support for responsive sizes, layouts, and custom class names.
 *
 * @component
 * @param {object} props - The properties object.
 * @param {string} [props.layout] - The layout to apply to the img element, such as "rounded".
 * @param {string} [props.className] - Additional class names for the outer container element.
 * @param {string} [props.imgClassName] - Additional class names for the img element.
 * @param {string} [props.src] - The direct URL of the image to display.
 * @param {object} [props.image] - The image object containing different formats and sizes.
 * @param {string} [props.format] - Specifies the format of the image. @see Image.propTypes
 * @param {string} [props.sizes] - The sizes attribute for responsive images. @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/img#attr-sizes
 * @param {string} props.alt - The alt text for the image.
 * @param {Function} [props.onLoad] - Callback function triggered when the image loads.
 * @returns {JSX.Element} The rendered Image component.
 *
 * You have to provide either
 * - the src prop
 * - the image, format and sizes props
 */
export const Image = ({ layout, className, imgClassName, src, image, format, sizes, alt, onLoad = () => {} }) => {
  if (typeof src !== 'string' && (typeof image !== 'object' || image[format] === null)) return <></>;
  /**
   * Extracts the width from the image size string.
   *
   * @param {string} size - The size key (e.g., "w3840h2560").
   * @returns {string} The width of the image.
   */
  const getWidth = (size) => {
    const end = size.indexOf('h');
    return size.substring(1, end > -1 ? end : undefined);
  };

  /** Class names */
  const classNames = { image: ['image'], img: [] };
  className && classNames.image.push(className);
  imgClassName && classNames.img.push(imgClassName);
  layout && classNames.img.push(`l-${layout}`);

  /** If the srcSet contains only one image, set that image as src attribute */
  const srcSetLength = image && format && sizes ? Object.keys(image[format]).length : 0;
  srcSetLength < 2 && image && format && (src = Object.values(image[format])[0]);

  /** Props */
  const srcProps =
    srcSetLength > 1
      ? {
          src: Object.values(image[format]).pop(),
          srcSet: Object.entries(image[format])
            .map(([size, image]) => `${image} ${getWidth(size)}w`)
            .join(','),
          sizes,
        }
      : {
          src,
        };

  return (
    <div className={classNames.image.join(' ')}>
      <img {...srcProps} className={classNames.img.join(' ')} alt={alt} onLoad={onLoad} />
    </div>
  );
};

Image.propTypes = {
  image: PropTypes.shape(imagePropTypes),
  layout: PropTypes.oneOf(['rounded']),
  className: PropTypes.string,
  imgClassName: PropTypes.string,
  src: PropTypes.string,
  format: PropTypes.oneOf(['landscape', 'portrait', 'square', 'resized']),
  sizes: PropTypes.string,
  alt: PropTypes.string.isRequired,
  onLoad: PropTypes.func,
};

/**
 * loadImage Utility
 *
 * Asynchronously loads an image and resolves or rejects based on the load success.
 *
 * @param {string|object} image - The image source URL or an image object.
 * @param {string} [format] - The format key for the image object (e.g., "landscape").
 * @returns {Promise<Event|Error>} Resolves on image load, rejects on error.
 *
 * If you provide an image object, you have to provide the format too
 */
export const loadImage = (image, format) =>
  new Promise((resolve, reject) => {
    const src =
      typeof image === 'string'
        ? image
        : typeof image === 'object' && format !== undefined
        ? Object.values(image[format]).pop()
        : null;

    if (src) {
      const imageLoader = document.createElement('img');
      imageLoader.src = src;
      imageLoader.addEventListener('load', resolve);
    } else {
      return reject('loadImage: src not found');
    }
  });

export default Image;
