import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';

/**
 * A Redux slice for the WordPress `pages` post type.
 */

// eslint-disable-next-line jsdoc/valid-types
/** @typedef {import('./appSlice.js').Page} Page A page */

export const pagesSlice = createSlice({
  name: 'pages',
  initialState: {
    isLoaded: false,
    /** @type {Page[]} */
    list: [],
    error: '',
  },
  reducers: {
    /**
     * Save the pages list.
     *
     * @param {object} state - The redux state.
     * @param {object} action - The reducer action.
     * @param {object[]} action.payload - The pages list.
     */
    saveList: (state, action) => {
      state.isLoaded = true;
      state.list = action.payload;
      process.env.NODE_ENV === 'development' && console.info(action.payload);
    },

    /**
     * Save the loading error.
     *
     * @param {object} state - The redux state.
     * @param {object} action - The reducer action.
     * @param {string} action.payload - The loading error.
     */
    saveError: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const { saveList, saveError } = pagesSlice.actions;

/**
 * Select the front page.
 *
 * @returns {object}
 */
export const selectFrontPage = createSelector(
  (state) => state.pages,
  (pages) =>
    pages.list.filter(
      (page) => page.pageName === 'frontpage' && page.lang === process.env.REACT_APP_DEFAULT_LANGUAGE
    )[0]
);

/**
 * Select a page by a given language.
 *
 * @param {object} state - The React state.
 * @param {string} pageName - The page name.
 * @param {string} language - The page language.
 * @returns {object}
 */
export const selectPage = createSelector(
  (state) => state.pages,
  (_, pageName) => pageName,
  (_, __, language) => language,
  (pages, pageName, language) => pages.list.filter((page) => page.pageName === pageName && page.lang === language)[0]
);

export default pagesSlice.reducer;
