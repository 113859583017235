import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

/** Redux */
import { selectPage } from '../../redux/pagesSlice';

/** Utils */
import { formatTel, formatUrl } from '../../util/String';

/** Components */
import LoginRegister from '../LoginRegister';
import Link from '../Link';
import logoSicona from '../../images/logo-sicona.svg';
import logoGrengland from '../../images/logo-grengland.png';
import logoGovernment from '../../images/logo-government.png';

const Footer = () => {
  const { i18n } = useTranslation();

  /** Redux */
  const { tel, url, email } = useSelector((state) => state.options.coords);
  const pageLegal = useSelector((state) => selectPage(state, 'legal', i18n.language));
  const pagePrivacy = useSelector((state) => selectPage(state, 'privacy', i18n.language));

  return (
    <footer className="layout footer">
      <div>
        <div className="footer-coords">
          <div className="footer-coords-author">
            <img src={logoSicona} alt="" />
            <div>
              <h3>Naturschutzsyndikat SICONA</h3>
              <p className="h6">
                12, rue de Capellen
                <br />
                L-8393 Olm
              </p>
              {(tel || url || email) && (
                <p>
                  {tel && (
                    <>
                      <Link color="white" title={tel} url={`tel:${formatTel(tel)}`} />
                      {(url || email) && <br />}
                    </>
                  )}
                  {url && (
                    <>
                      <Link color="white" title={formatUrl(url)} url={url} target="_blank" />
                      {email && <br />}
                    </>
                  )}
                  {email && <Link color="white" title={email} url={`mailto:${email}`} />}
                </p>
              )}
            </div>
          </div>
          <div className="footer-coords-links">
            <LoginRegister loginButtonColor="white" registerButtonColor="primary" />
            <div className="footer-links">
              <Link color="white" title={pageLegal.title} url={pageLegal.url} />
              <Link color="white" title={pagePrivacy.title} url={pagePrivacy.url} />
            </div>
          </div>
        </div>
        <p className="footer-sponsors">
          <img src={logoGrengland} alt="Zesummen aarteräicht Gréngland erhalen" />
          <img
            src={logoGovernment}
            alt="Le gouvernement du Grand-Duché de Luxembourg - Ministère de l’Environnement, du Climat et de la Biodiversité"
          />
        </p>
      </div>
    </footer>
  );
};

export default Footer;
