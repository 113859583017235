import { createSlice } from '@reduxjs/toolkit';

/**
 * A Redux slice for the WordPress ACF `options` page.
 */

/**
 * @typedef {object} Contact - A contact.
 * @property {string} name - The name of the contact.
 * @property {string} email - The e-mail of the contact.
 */

export const optionsSlice = createSlice({
  name: 'options',
  initialState: {
    /** @type {Contact[]} */
    // contacts: [],
    // social: {
    //   facebook: '',
    //   instagram: '',
    //   linkedin: '',
    //   twitter: '',
    //   youtube: '',
    // },
    settings: {
      recaptcha: '',
      maps: '',
    },
    coords: {
      tel: '',
      url: '',
      email: '',
    },
    lostPasswordUrl: '',
    error: '',
  },
  reducers: {
    /**
     * Save the options.
     *
     * @param {object} state - The redux state.
     * @param {object} action - The reducer action.
     * @param {object} action.payload - The reducer data.
     * @param {object} action.payload.contacts - The contacts.
     * @param {object} action.payload.social - The social url's.
     * @param {object} action.payload.settings - The contact settings.
     * @param {object} action.payload.coords - The contact coords.
     * @param {object} action.payload.lostPasswordUrl - The url of the "lost password" page.
     */
    saveOptions: (state, action) => {
      // state.contacts = action.payload.contacts;
      // state.social = action.payload.social;
      state.settings = action.payload.settings;
      state.coords = action.payload.coords;
      state.lostPasswordUrl = action.payload.lostPasswordUrl;
    },

    /**
     * Save the loading error.
     *
     * @param {object} state - The redux state.
     * @param {object} action - The reducer action.
     * @param {string} action.payload - The loading error.
     */
    saveError: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const { saveOptions, saveError } = optionsSlice.actions;

export default optionsSlice.reducer;
